import dayjs from "dayjs";
import { UcccNotifications } from "../../hooks/react-query/linehaul/useUcccNotifications";

export const notifications = {
  default: {
    notifications: [
      {
        proNumber: "9848347",
        createdOn: dayjs(),
        destinationSic: {
          id: 1,
          code: "ONT",
          timeZone: "America/New_York",
        },
      },
      {
        proNumber: "9848348",
        createdOn: dayjs(),
        destinationSic: {
          id: 2,
          code: "EWR",
          timeZone: "America/New_York",
        },
      },
      {
        proNumber: "9848349",
        createdOn: dayjs(),
        destinationSic: {
          id: 3,
          code: "ATL",
          timeZone: "America/New_York",
        },
      },
    ],
  },
} satisfies Record<string, UcccNotifications>;
