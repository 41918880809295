import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  colors,
} from "@mui/material";
import { useCallback, useReducer, useState } from "react";
import dayjs from "dayjs";
import { Close } from "@mui/icons-material";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { dateToInt } from "../../../../utils/dateTimeHelper";
import { LoadingButton } from "../../../../components/shared/LoadingButton";
import { useMergeRoutesTraps } from "../../../../hooks/react-query/preplanning/useRouteTraps";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useActionsMenuContext } from "../ActionsMenuContext";
import { formatDateTimeTableField } from "../../../../utils/tableFormat";
import { DaylightDateFormat } from "../../../../constants/DaylightDateFormat";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import ClosedStatusWarning from "../ClosedStatusWarning";
import { useFormattedPlannedRoutesAndTraps } from "../../../../hooks/react-query/useFormattedPlannedRoutesAndTraps";
import { isEquipmentRestricted } from "../../inboundPlanning/tables/PlanTable";
import {
  planningStatus,
  type NonDeliveredStatuses,
} from "../../../../types/status.type";
import {
  initialState,
  mergeRoutesTrapsDialogReducer,
} from "./MergeRoutesTrapsDialogReducer";
import { MergeRoutesTrapsStepOneOld } from "./MergeRoutesTrapsStepOneOld";
import {
  MergeRoutesTrapsStepOne,
  RouteOrTrap,
} from "./MergeRoutesTrapsStepOne";
import {
  MergeRoutesTrapsStepTwo,
  ValidationErrors,
} from "./MergeRoutesTrapsStepTwo";

interface MergeRoutesTrapsDialogProps {
  selectedRouteIds: number[];
  selectedTrapIds: number[];
  onCancel: () => void;
}

export const MergeRoutesTrapsDialog = ({
  selectedRouteIds,
  selectedTrapIds,
  onCancel,
}: MergeRoutesTrapsDialogProps) => {
  const mergeTrapsDialogV2Enabled = useIsFeatureFlagEnabled(
    "inbound-merge-trap-dialog-v2-client"
  );

  const isRemoveDoorNotAllowed = useIsFeatureFlagEnabled(
    "inbound-remove-door-not-allowed-client"
  );

  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(selectedDate);

  const { clearSelection, isUpdatingClosedRouteOrTrap } =
    useActionsMenuContext();

  const { data: allPlannedRoutesAndTraps = [] } =
    useFormattedPlannedRoutesAndTraps(planDate, serviceCenter.id, []);

  const [bottomErrorMessage, setBottomErrorMessage] = useState<string>("");
  const [formValidationErrors, setFormValidationErrors] =
    useState<ValidationErrors>({});

  const plannedRoutes = allPlannedRoutesAndTraps.filter(
    (x) => x.type === "delivery_route"
  );
  const plannedTraps = allPlannedRoutesAndTraps.filter(
    (x) => x.type === "delivery_trap"
  );

  const [dialogState, dispatch] = useReducer(mergeRoutesTrapsDialogReducer, {
    ...initialState,
    selectedTrapIds,
    selectedRouteIds,
    mergeIntoType:
      selectedTrapIds.length > 0 && selectedRouteIds.length === 0
        ? "trap"
        : "route",
  });

  const {
    selectedRouteIds: routeIdsToMerge,
    selectedTrapIds: trapIdsToMerge,
    mergeDestination,
    mergeIntoType,
    currentStep,
  } = dialogState;

  const showClosedStatusWarning = isUpdatingClosedRouteOrTrap(
    mergeDestination.id
  );

  const selectedRoutes: RouteOrTrap[] = plannedRoutes
    .filter((route) => routeIdsToMerge.includes(route.id))
    .map((route) => ({
      id: route.id,
      name: route.name,
      status: route.status,
      type: "route",
    }));

  const selectedTraps: RouteOrTrap[] = plannedTraps
    .filter((trap) => trapIdsToMerge.includes(trap.id))
    .map((trap) => ({
      id: trap.id,
      name: trap.name,
      status: trap.status,
      type: "trap",
    }));

  const routeShipmentIds = plannedRoutes
    .filter((r) => routeIdsToMerge.includes(r.id))
    .flatMap((r) => r.shipmentIds);

  const trapShipmentIds = plannedTraps
    .filter((r) => trapIdsToMerge.includes(r.id))
    .flatMap((r) => r.shipmentIds);

  const mergeRoutesAndTraps = useMergeRoutesTraps(() => {
    const successMessage = toastMessage.inbound.mergeRoutesAndTraps.success(
      trapShipmentIds.length + routeShipmentIds.length,
      mergeDestination.customName,
      mergeIntoType === "route"
    );
    clearSelection?.();
    toast(successMessage, { variant: "success" });
    closeDialog();
  });

  const closeDialog = useCallback(() => {
    onCancel();
    dispatch({
      type: "clear",
    });
  }, [onCancel]);

  const validateForm = () => {
    const validationErrors: ValidationErrors = {};
    if (mergeIntoType === "route") {
      if (!mergeDestination.customName) {
        validationErrors.name = "Please add a Route name.";
      }
      if (!mergeDestination.startTime) {
        validationErrors.startTime = "Please add a start time.";
      }
    } else {
      if (!mergeDestination.customName) {
        validationErrors.name = "Please add a Trap name.";
      }
    }

    setFormValidationErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    if (routeIdsToMerge.length + trapIdsToMerge.length < 2) {
      toast(toastMessage.inbound.mergeRoutesAndTraps.selectAtLeastTwo, {
        variant: "warning",
      });
      return;
    }

    const hasEmptyTraps = plannedTraps.some(
      (item) =>
        trapIdsToMerge.includes(item.id) && item.shipmentIds.length === 0
    );
    const hasEmptyRoutes = plannedRoutes.some(
      (item) =>
        routeIdsToMerge.includes(item.id) && item.shipmentIds.length === 0
    );

    const hasAllRoutesNonDepartedStatus = selectedRoutes.every((p) =>
      [planningStatus.CPLN, planningStatus.CLDK, planningStatus.CLDV].includes(
        p.status as NonDeliveredStatuses
      )
    );

    if (hasEmptyTraps || hasEmptyRoutes) {
      toast(
        toastMessage.inbound.mergeRoutesAndTraps
          .selectOnlyRoutesTrapsWithShipments,
        {
          variant: "warning",
        }
      );
    } else if (!hasAllRoutesNonDepartedStatus) {
      toast(
        toastMessage.inbound.mergeRoutesAndTraps.selectOnlyNonDispatchedRoutes,
        {
          variant: "warning",
        }
      );
    } else {
      if (isRemoveDoorNotAllowed) {
        const { restricted, missingEquipment } = isEquipmentRestricted({
          type: plannedTraps.length > 0 ? "delivery_trap" : "delivery_route",
          status: mergeDestination.status,
          doorId: mergeDestination.doorId,
          trailerId: mergeDestination.trailerId,
          straightTruckId: mergeDestination.straightTruckId,
        });

        if (restricted && missingEquipment) {
          const errorMessage =
            toastMessage.inbound.removeEquipmentNotAllowedFromTrapOrRoute(
              missingEquipment,
              plannedTraps.length > 0 ? "Trap" : "Route"
            );
          toast(errorMessage, { variant: "error" });
        }
      }

      mergeRoutesAndTraps.mutate({
        trapIdsToMerge,
        routeIdsToMerge,
        mergeIntoSelect: mergeIntoType,
        mergeInto: mergeDestination.id ?? 0,
        tractorEquipmentId: mergeDestination.tractorId ?? null,
        trailerEquipmentId: mergeDestination.trailerId ?? null,
        straightTruckEquipmentId: mergeDestination.straightTruckId ?? null,
        doorId: mergeDestination.doorId,
        driverId: mergeDestination.driverId ?? null,
        startTime: mergeDestination.startTime
          ? dayjs(mergeDestination.startTime)
              .tz(serviceCenter.timeZone, true)
              .toISOString()
          : dayjs().utc().toISOString(),
        date: planDate,
        sicId: serviceCenter.id,
        presetRouteId: mergeDestination.id ?? 0,
        customName:
          mergeDestination.customName !== mergeDestination.originalName
            ? mergeDestination.customName
            : undefined,
      });
    }
  };

  const handleBack = () => {
    setFormValidationErrors({});
    dispatch({
      type: "go_to_previous_step",
    });
  };

  const handleNext = (shouldBypassValidation: boolean) => {
    setBottomErrorMessage("");
    if (shouldBypassValidation || handleRouteTrapSelectionValidation()) {
      dispatch({
        type: "go_to_next_step",
      });
    }
  };

  const handleRouteTrapSelectionValidation = () => {
    if (currentStep === 1 && mergeDestination.id === null) {
      const type =
        routeIdsToMerge.length > 0 && trapIdsToMerge.length > 0
          ? "route or trap"
          : routeIdsToMerge.length
            ? "route"
            : "trap";
      setBottomErrorMessage(`Please select a ${type}.`);
      return false;
    }

    return true;
  };

  return (
    <Dialog open onClose={closeDialog} maxWidth="sm" fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Merge Routes/Traps
        </DialogTitle>
        {showClosedStatusWarning && <ClosedStatusWarning />}
        <DialogContent sx={{ minWidth: "500px", padding: "0px" }}>
          <Box position="absolute" right={10} top={10}>
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Box>
          {currentStep === 1 ? (
            mergeTrapsDialogV2Enabled ? (
              <MergeRoutesTrapsStepOne
                dialogState={dialogState}
                dispatch={dispatch}
                selectedTraps={selectedTraps}
                selectedRoutes={selectedRoutes}
                mergeDestinationOptions={allPlannedRoutesAndTraps.map(
                  (routeOrTrap) => ({
                    id: routeOrTrap.id,
                    type: routeOrTrap.type,
                    doorId: routeOrTrap.door,
                    startTime: routeOrTrap.startTime
                      ? dayjs(
                          formatDateTimeTableField(
                            routeOrTrap.startTime,
                            DaylightDateFormat.DATE_TIME_12,
                            serviceCenter.timeZone
                          )
                        ).toISOString()
                      : null,
                    trailerId: routeOrTrap.trailer?.id ?? null,
                    straightTruckId: routeOrTrap.straightTruck?.id ?? null,
                    tractorId: routeOrTrap.tractor?.id ?? null,
                    customName: routeOrTrap.name,
                    originalName: routeOrTrap.name,
                    planDate: routeOrTrap.date,
                    driverId: routeOrTrap.driver?.id ?? null,
                    status: routeOrTrap.status,
                  })
                )}
                handleNext={handleNext}
              />
            ) : (
              <MergeRoutesTrapsStepOneOld
                dialogState={dialogState}
                dispatch={dispatch}
                selectedTraps={selectedTraps}
                selectedRoutes={selectedRoutes}
                plannedRoutes={allPlannedRoutesAndTraps}
                handleNext={handleNext}
              />
            )
          ) : (
            <MergeRoutesTrapsStepTwo
              validationErrors={formValidationErrors}
              dialogState={dialogState}
              dispatch={dispatch}
              mergeDestinationName={mergeDestination.originalName}
              showRouteEquipmentSelectors={false}
            />
          )}
          <Divider sx={{ marginTop: "10px" }} />
          <Box ml={3} mt={1} height={20}>
            {bottomErrorMessage && (
              <Typography
                role="alert"
                fontSize="small"
                color={(theme) => theme.palette.error.main}
              >
                {bottomErrorMessage}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
          <Button
            variant="outlined"
            onClick={currentStep === 1 ? closeDialog : handleBack}
            color="inherit"
            sx={{
              border: "none",
              color: colors.blue[700],
              textTransform: "none",
            }}
          >
            {currentStep === 1 ? "Cancel" : "Back"}
          </Button>
          {currentStep === 2 ? (
            <LoadingButton
              isLoading={mergeRoutesAndTraps.isPending}
              loadingLabel="Merging..."
            >
              Merge
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleNext(false)}
              aria-disabled={bottomErrorMessage.length > 0}
              sx={{
                border: "none",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
