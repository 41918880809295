import { LaneDetails } from "../../../hooks/react-query/outbound/freightDetails/useFreightDetails";

export const laneDetailsTonnage = {
  default: {
    summary: {
      bills: 111,
      pallets: 120,
      pieces: 23,
      weight: 9200,
      indicators: {
        lanesWithUnassignedShipmentsCount: 3,
        lanesWithShipmentsThatWillMissServiceCount: 6,
        lanesWithPullsWithNoTrailerCount: 3,
        lanesWithPullsWithNoTripsAndTrapsCount: 4,
        lanesWithNoDoorCount: 2,
      },
      loadedBills: 10,
      notLoadedBills: 110,
      loadedWeight: 1200,
      notLoadedWeight: 8000,
      loadedPieces: 12,
      notLoadedPieces: 48,
      loadedPallets: 23,
      notLoadedPallets: 56,
    },
    lanes: [
      {
        destinationSicId: 20,
        destinationSic: "CHT",
        indicators: {
          unassignedShipmentsCount: 1,
          shipmentsThatWillMissServiceCount: 1,
          pullsWithNoTrailerCount: 1,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: true,
        },
        laneType: 3,
        laneIdType: "20.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "CC",
            color: "#EA5E5A",
            bills: 6,
          },
        ],
        doors: [],
      },
      {
        destinationSicId: 111,
        destinationSic: "ONT",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 1,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 1,
          hasNoDoors: true,
        },
        laneType: 1,
        laneIdType: "111.1",
        doorsCount: 1,
        publishedTrips: 2,
        plannedPulls: 1,
        loadedWeight: 100000,
        capacityWeight: 120000,
        totalWeight: 120000,
        bills: 20,
        pieces: 0,
        pallets: 20,
        billsByTags: [
          {
            id: 1,
            name: "CC",
            color: "#EA5E5A",
            bills: 3,
          },
          {
            id: 2,
            name: "APTR",
            color: "#777DE9",
            bills: 5,
          },
          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 1,
            bills: 20,
            pieces: 0,
            pallets: 20,
            weight: 1000,
            billsByTags: [
              {
                id: 2,
                name: "APTR",
                color: "#777DE9",
                bills: 5,
              },
              {
                id: 3,
                name: "VC",
                color: "#AA7EA0",
                bills: 6,
              },
            ],
          },
          {
            doorNumber: 2,
            bills: 20,
            pieces: 0,
            pallets: 20,
            weight: 1000,
            billsByTags: [
              {
                id: 2,
                name: "APTR",
                color: "#777DE9",
                bills: 5,
              },
              {
                id: 3,
                name: "VC",
                color: "#AA7EA0",
                bills: 6,
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 115,
        destinationSic: "HTX",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 1,
          pullsWithNoTrailerCount: 1,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: true,
        },
        laneType: 1,
        laneIdType: "115.1",
        doorsCount: 2,
        publishedTrips: 3,
        plannedPulls: 2,
        loadedWeight: 80000,
        capacityWeight: 110000,
        totalWeight: 110000,
        bills: 15,
        pieces: 0,
        pallets: 15,
        billsByTags: [
          {
            id: 2,
            name: "APTR",
            color: "#777DE9",
            bills: 5,
          },
          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 2,
            bills: 15,
            pieces: 0,
            pallets: 15,
            weight: 1000,
            billsByTags: [
              {
                id: 2,
                name: "TX",
                bills: 1,
                color: "#FF5722",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 2,
        destinationSic: "LAX",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 0,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 1,
          hasNoDoors: true,
        },
        laneType: 2,
        laneIdType: "2.2",
        doorsCount: 3,
        publishedTrips: 4,
        plannedPulls: 3,
        loadedWeight: 120000,
        capacityWeight: 140000,
        totalWeight: 140000,
        bills: 25,
        pieces: 0,
        pallets: 25,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },
          {
            id: 2,
            name: "CC",
            color: "#EA5E5A",
            bills: 5,
          },
          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 3,
            bills: 25,
            pieces: 0,
            pallets: 25,
            weight: 1000,
            billsByTags: [
              {
                id: 3,
                name: "CA",
                bills: 1,
                color: "#4CAF50",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 444,
        destinationSic: "THX",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 0,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: true,
        },
        laneType: 3,
        laneIdType: "444.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 56,
        destinationSic: "BOS",
        indicators: {
          unassignedShipmentsCount: 1,
          shipmentsThatWillMissServiceCount: 1,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 1,
          hasNoDoors: true,
        },
        laneType: 0,
        laneIdType: "56.0",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 10,
        destinationSic: "BWI",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 0,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: true,
        },
        laneType: 3,
        laneIdType: "10.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 114,
        destinationSic: "DAL",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 0,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: true,
        },
        laneType: 3,
        laneIdType: "114.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 16,
        destinationSic: "DFW",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 0,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: false,
        },
        laneType: 3,
        laneIdType: "16.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 1,
        destinationSic: "EWR",
        indicators: {
          unassignedShipmentsCount: 1,
          shipmentsThatWillMissServiceCount: 1,
          pullsWithNoTrailerCount: 1,
          pullsWithNoTripsAndTrapsCount: 1,
          hasNoDoors: false,
        },
        laneType: 3,
        laneIdType: "1.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 5626,
        destinationSic: "IND",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 1,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: false,
        },
        laneType: 3,
        laneIdType: "5626.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
      {
        destinationSicId: 5622,
        destinationSic: "CLE-PITD",
        indicators: {
          unassignedShipmentsCount: 0,
          shipmentsThatWillMissServiceCount: 0,
          pullsWithNoTrailerCount: 0,
          pullsWithNoTripsAndTrapsCount: 0,
          hasNoDoors: false,
        },
        laneType: 3,
        laneIdType: "5622.3",
        doorsCount: 4,
        publishedTrips: 5,
        plannedPulls: 4,
        loadedWeight: 110000,
        capacityWeight: 130000,
        totalWeight: 130000,
        bills: 30,
        pieces: 0,
        pallets: 30,
        billsByTags: [
          {
            id: 1,
            name: "UC",
            color: "#FBC02D",
            bills: 3,
          },

          {
            id: 3,
            name: "VC",
            color: "#AA7EA0",
            bills: 6,
          },
        ],
        doors: [
          {
            doorNumber: 4,
            bills: 30,
            pieces: 0,
            pallets: 30,
            weight: 1000,
            billsByTags: [
              {
                id: 4,
                name: "DAL",
                bills: 1,
                color: "#2196F3",
              },
            ],
          },
        ],
      },
    ],
  },
} as const satisfies Record<string, LaneDetails>;
