import { useState } from "react";
import { Print } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import PrintDialog from "../../../../components/shared/printTable/PrintDialog";
import {
  PrintResult,
  usePrintBulkReport,
} from "../../../../hooks/react-query/usePrint";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import Loading from "../../../../components/shared/layout/Loading";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";

type PlanningStatus = string;

type PrintIconButtonProps = {
  routeOrTrap: PlanTableFormat;
};

export const PrintIconButton = ({ routeOrTrap }: PrintIconButtonProps) => {
  const showPrintDialog = useIsFeatureFlagEnabled(
    "inbound-planning-print-dialog-client"
  );
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  const routeOrTrapStatus = new Set<PlanningStatus>([
    "DISP",
    "CLDV",
    "CPLN",
    "CLDK",
    "TRAP",
    "TPLN",
    "DEPT",
  ]);

  const onPrintSuccess = (result: PrintResult) => {
    if (result.state === "Processed") {
      window.open(result.url, "_blank");
    }
  };

  const { mutate: printBulkReport, isPending } =
    usePrintBulkReport(onPrintSuccess);

  const handleBulkPrintClick = () => {
    if (routeOrTrap.bills <= 0) {
      toast(
        toastMessage.inbound.print.routeOrTrapWithNoBills(
          routeOrTrap.type === "delivery_route" ? "route" : "trap"
        ),
        {
          variant: "warning",
        }
      );
      return;
    }

    if (routeOrTrapStatus.has(routeOrTrap.status) && showPrintDialog) {
      setIsPrintDialogOpen(true);
      return;
    }
    printBulkReport({
      sicId: serviceCenter.id,
      planDate: selectedDate,
      cityLoadCard: {
        routes: routeOrTrap.type === "delivery_route" ? [routeOrTrap.id] : [],
      },
      trapLoadCard: {
        trapIds: routeOrTrap.type === "delivery_trap" ? [routeOrTrap.id] : [],
      },
    });
  };

  return (
    <>
      <Tooltip title={`Print ${routeOrTrap.name}`}>
        <IconButton onClick={handleBulkPrintClick}>
          {isPending ? <Loading containerWidth={32} size={32} /> : <Print />}
        </IconButton>
      </Tooltip>
      {isPrintDialogOpen && (
        <PrintDialog
          onCancel={() => {
            setIsPrintDialogOpen(false);
          }}
          planTableRowData={[routeOrTrap]}
          setIsPrintDialogOpen={setIsPrintDialogOpen}
        />
      )}
    </>
  );
};
