import { memo, PropsWithChildren, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { keepSpecificParams } from "../../../utils/searchParamHelper";
import { SELECTED_DATE_PARAM_KEY } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { UNPLANNED_SELECTED_DATE } from "../../../hooks/useUnplannedDatetimeSearchParam";
import { tableSectionToIconMap } from "../../../pages/inbound/shared/TableActionBar";
import { PlanType } from "../../../types/planning/plan.type";
import { InboundTrailerIcon } from "../../../pages/inbound/shared/InboundTrailerIcon";
import { Tooltip } from "../Tooltip";

export const TRAILER_TITLE_PARAM = "trailerTitle";
export const SHIPMENT_TABLE_PARAM = "shipmentTable";
const PLAN_TYPE_PARAM = "planType";
const SHIPMENT_IDS_PARAM = "shipmentIds";

type TableLinkProps = {
  id: string;
  title: string;
  shipmentIds?: number[];
  icon?: keyof typeof tableSectionToIconMap;
  isLockedRoute?: boolean | null;
  planType?: PlanType;
};

const TableLink = ({
  id,
  title,
  shipmentIds,
  children,
  icon,
  isLockedRoute = false,
  planType,
}: PropsWithChildren<TableLinkProps>) => {
  const [search] = useSearchParams();

  const currentSearchParams = useMemo(() => {
    const newSearchParam = keepSpecificParams(new URLSearchParams(search), [
      SELECTED_DATE_PARAM_KEY,
      UNPLANNED_SELECTED_DATE,
    ]);
    newSearchParam.set(SHIPMENT_TABLE_PARAM, "true");
    newSearchParam.set(TRAILER_TITLE_PARAM, title);
    if (planType) {
      newSearchParam.set(PLAN_TYPE_PARAM, planType);
    }
    if (shipmentIds) {
      newSearchParam.set(SHIPMENT_IDS_PARAM, shipmentIds.join(","));
    }
    return newSearchParam;
  }, [search, title, planType, shipmentIds]);

  return (
    <Link
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: 8,
        fontWeight: 700,
      }}
      to={{
        pathname: `${id}/shipments`,
        search: currentSearchParams.toString(),
      }}
    >
      {icon && (
        <Tooltip content={isLockedRoute ? "Locked route" : ""}>
          <InboundTrailerIcon
            isLockedRoute={Boolean(isLockedRoute)}
            icon={icon}
          />
        </Tooltip>
      )}
      {children}
    </Link>
  );
};
export default memo(TableLink);
