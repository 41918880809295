// Note: This is a partial list of statuses that we reference on the client.
// There are over 60 statuses in the DB and per the backend team they may change over time,
// so we are typing status as a string on the client.

export const planningStatus = {
  CPLN: "CPLN",
  TPLN: "TPLN",
  CLDV: "CLDV",
  CLDK: "CLDK",
  TRAP: "TRAP",
  DISP: "DISP",
} as const;

const finalStatus = {
  DEPT: "DEPT",
} as const;

export const allStatuses = {
  ...planningStatus,
  ...finalStatus,
} as const;

export const finalStatuses: string[] = Object.values(finalStatus);

export type NonDeliveredStatuses = "CPLN" | "CLDK" | "CLDV";
