import { Stack } from "@mui/system";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { GlobalNav } from "../components/shared/nav/GlobalNav";
import { useGetEnabledDepartments } from "../featureFlags/useGetEnabledDepartments";
import { WorkInProgress } from "../components/shared/WorkInProgress";
import Loading from "../components/shared/layout/Loading";
import { PageNotFound } from "../pages/PageNotFound";
import { Home } from "../pages/Home";
import {
  isRedirectToSegment,
  MySegment,
  useMySegments,
} from "../hooks/react-query/splitIoApi/useMySegments";
import {
  DOCK_AUTOMATION_URL_KEY,
  LINEHAUL_URL_KEY,
  OPERATIONS_URL_KEY,
} from "../components/shared/nav/useDepartments";
import { useIsFeatureFlagEnabled } from "../featureFlags/useIsFeatureFlagEnabled";
import { UnauthorizedErrorDialog } from "../components/shared/UnauthorizedErrorDialog";
import { useUnauthorizedErrorDialogStore } from "../stores/unauthorizedErrorDialog";
import { displayMessage } from "../constants/strings";
import { AuthUserWithEmail } from "../hooks/react-query/useAuthenticatedUser";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { settingsRoutes } from "./Settings";
import { useInboundRoutes } from "./Inbound";
import { useOutboundRoutes } from "./Outbound";
import { linehaulRoutes } from "./Linehaul";

// TODO : This is a temporary workaround to redirect users to the correct department.
// Post-launch, we should have a more robust solution for this.
// If the user has only one segment, redirect them to the appropriate department.
function populateAndReturnTemporaryRedirectRoutes(
  mySegments: MySegment[]
): RouteObject[] {
  const redirectRoutes: RouteObject[] = [];

  if (
    mySegments.length === 1 &&
    mySegments[0] &&
    isRedirectToSegment(mySegments[0].name)
  ) {
    if (mySegments[0].name === "operations_inbound_users") {
      redirectRoutes.push({
        index: true,
        element: <Navigate to={`/${OPERATIONS_URL_KEY}`} replace />,
      });
    } else {
      redirectRoutes.push({
        index: true,
        element: <Navigate to={`/${LINEHAUL_URL_KEY}`} replace />,
      });
    }
  } else {
    redirectRoutes.push({
      index: true,
      element: <Home />,
    });
  }

  return redirectRoutes;
}

type ProtectedRoutesProps = {
  user: AuthUserWithEmail;
};

export function ProtectedRoutesProvider({ user }: ProtectedRoutesProps) {
  const { isUnauthorizedErrorDialogOpen, setIsUnauthorizedErrorDialogOpen } =
    useUnauthorizedErrorDialogStore();

  const { isLoading: isLoadingDepartmentFlags, departmentIsEnabledMap } =
    useGetEnabledDepartments();

  const { data: mySegments = [], isLoading: isMySegmentsLoading } =
    useMySegments(user.userId);

  const operationsInboundEnabled = useIsFeatureFlagEnabled(
    "operations-inbound-client"
  );

  const operationsOutboundEnabled = useIsFeatureFlagEnabled(
    "operations-outbound-client"
  );

  const outboundRoutes = useOutboundRoutes();
  const inboundRoutes = useInboundRoutes();

  if (isLoadingDepartmentFlags || isMySegmentsLoading) return <Loading />;

  // TODO : This is a temporary workaround to redirect users to the correct department.
  const protectedRoutes = populateAndReturnTemporaryRedirectRoutes(mySegments);

  if (departmentIsEnabledMap[OPERATIONS_URL_KEY]) {
    protectedRoutes.push({
      path: OPERATIONS_URL_KEY,
      lazy: () => import("../pages/ServiceCentersHandler"),
      shouldRevalidate: () => false,
      children: [
        {
          index: true,
          lazy: () => import("../pages/ServiceCentersHandler"),
        },
        {
          path: ":serviceCenterCode",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <Navigate to="inbound/unplanned-freight" replace />,
            },
            ...(operationsInboundEnabled ? inboundRoutes : []),
            ...(operationsOutboundEnabled ? outboundRoutes : []),
            {
              path: "dispatch",
              errorElement: <ErrorBoundary />,
              lazy: () => import("../pages/dispatch/Dispatch"),
            },
            {
              path: "equipment-inventory",
              errorElement: <ErrorBoundary />,
              element: <WorkInProgress />,
            },
          ],
        },
      ],
    });
  }

  if (departmentIsEnabledMap[LINEHAUL_URL_KEY]) {
    protectedRoutes.push(...linehaulRoutes);
  }

  if (departmentIsEnabledMap[DOCK_AUTOMATION_URL_KEY]) {
    protectedRoutes.push({
      path: DOCK_AUTOMATION_URL_KEY,
      element: <WorkInProgress />,
    });
  }

  protectedRoutes.push(...settingsRoutes);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Stack height="100%">
          <GlobalNav />
          <Stack flex={1} minHeight={0} overflow="hidden" component="main">
            <Outlet />
          </Stack>
        </Stack>
      ),
      children: protectedRoutes,
      errorElement: <ErrorBoundary />,
    },
    // unprotected routes
    { path: "login", element: <Navigate to="/" replace /> },
    { path: "*", element: <PageNotFound /> },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      {isUnauthorizedErrorDialogOpen && (
        <UnauthorizedErrorDialog
          close={() => setIsUnauthorizedErrorDialogOpen(false)}
          message={displayMessage.default.unauthorizedError(user.email)}
        />
      )}
    </>
  );
}
