// CSpell:ignore Lato
import { createTheme, hexToRgb } from "@mui/material";
import "@mui/x-data-grid/themeAugmentation";
// eslint-disable-next-line import/no-empty-named-blocks
import type {} from "@mui/x-date-pickers/themeAugmentation";
declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    DefaultButtonBackground?: string;
  }
}

// NOTE
// We need to add an eslint-disable-next-line to import of
// "@mui/x-date-pickers/themeAugmentation" above as it's just a type definition file.
// Link to issue in Github: https://github.com/mui/material-ui/issues/27704#issuecomment-897434343
export const AppTheme = createTheme({
  palette: {
    primary: {
      dark: "#014A80",
      main: "#026AB7",
      light: "#44AFFF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#0167B3",
    },
    background: {
      default: "#FAFAFA",
      DefaultButtonBackground: "rgba(0, 0, 2055, 0.08)",
      paper: "#FFFFFF",
    },
    info: {
      main: "rgba(25, 118, 210, 0.14)",
      light: "#1976D214",
    },
    success: {
      light: "#3cb371", // MediumSeaGreen
      main: "#008000", // Green
    },
    grey: {
      "900": "#000000de",
      "700": "#00000098",
      "600": "#00000091",
      "100": "#0000001f",
      "50": "#00000009",
    },
    action: {
      selected: "#E3EEF9",
    },
  },
  typography: {
    fontFamily: "Lato",
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        rowsPerPageOptions: [20, 50, 100, 500, 1000],
        density: "comfortable",
        initialState: {
          columns: { columnVisibilityModel: { id: false, active: false } },
          pagination: { pageSize: 100 },
        },
      },
    },
    MuiMultiSectionDigitalClockSection: {
      // Increase the minWidth of the time selection sections to give space for scrolling
      styleOverrides: {
        root: {
          minWidth: "75px",
        },
        item: {
          minWidth: "67px",
        },
      },
    },
  },
});

export const hexToRgba = (hex: string, alpha = 1) => {
  const rgb = hexToRgb(hex); // "rgb(255, 87, 51)"
  return rgb.replace("rgb", "rgba").replace(")", `, ${alpha})`);
};
