import { Dispatch, useMemo } from "react";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";

import { planningStatus } from "../../../types/status.type";
import { PlanTableFormat } from "../../../types/planning/plan.type";
import PrintTable from "./PrintTable";
import { PrintTableProvider } from "./PrintTableContext";
import PrintDialogActions from "./PrintDialogActions";
import { RowData } from "./types";
import PrintDialogTitle from "./PrintDialogTitle";
import { getColumns } from "./utils";

type CheckboxState = {
  isInboundLoadCard: boolean;
  isDeliveryManifest: boolean;
  isDeliveryReceipts: boolean;
  isTrapLoadCard: boolean;
};

interface PrintDialogProps {
  onCancel: () => void;
  planTableRowData: PlanTableFormat[];
  setIsPrintDialogOpen: Dispatch<React.SetStateAction<boolean>>;
}

const getCheckboxState = (status: string, type: string): CheckboxState => {
  if (status === "CPLN") {
    return {
      isInboundLoadCard: true,
      isDeliveryManifest: false,
      isDeliveryReceipts: false,
      isTrapLoadCard: false,
    };
  }
  if (status === "TPLN" || status === "TRAP") {
    return {
      isInboundLoadCard: false,
      isDeliveryManifest: false,
      isDeliveryReceipts: false,
      isTrapLoadCard: true,
    };
  }

  if (!(status in planningStatus)) {
    return {
      isInboundLoadCard: false,
      isDeliveryManifest: true,
      isDeliveryReceipts: true,
      isTrapLoadCard: false,
    };
  }

  if (type === "delivery_trap" && status === "CLDK") {
    return {
      isInboundLoadCard: false,
      isDeliveryManifest: false,
      isDeliveryReceipts: false,
      isTrapLoadCard: true,
    };
  }

  return {
    isInboundLoadCard:
      status !== planningStatus.DISP && status !== planningStatus.CLDV,
    isDeliveryManifest: status !== planningStatus.CLDK,
    isDeliveryReceipts: status !== planningStatus.CLDK,
    isTrapLoadCard: status === planningStatus.TPLN,
  };
};

export const sortPlanTableRowDataByDoorTypeAndName = (
  data: Partial<PlanTableFormat>[]
): Partial<PlanTableFormat>[] =>
  data.sort((a, b) => {
    const doorA = a.door ?? 0;
    const doorB = b.door ?? 0;

    if (a.type === "delivery_route" && b.type === "delivery_trap") return -1;
    if (a.type === "delivery_trap" && b.type === "delivery_route") return 1;

    if (doorA > 0 && doorB === 0) return -1;
    if (doorA === 0 && doorB > 0) return 1;
    if (doorA > 0 && doorB > 0) return doorA - doorB;

    const nameA = a.name ?? "";
    const nameB = b.name ?? "";

    return nameA.localeCompare(nameB);
  });
const PrintDialog = ({
  onCancel,
  planTableRowData,
  setIsPrintDialogOpen,
}: PrintDialogProps) => {
  const isBulkPrinting = planTableRowData.length > 1;

  const plansWithBills = planTableRowData.filter((plan) => plan.bills > 0);

  const sortedData = useMemo(
    () => sortPlanTableRowDataByDoorTypeAndName(plansWithBills),
    [plansWithBills]
  );

  const printTableRows: RowData[] = sortedData
    .filter((row) => row.id !== undefined && row.status !== undefined)
    .map((row) => {
      const checkboxStates = getCheckboxState(row.status ?? "", row.type ?? "");

      return {
        id: row.id ?? 0,
        route: row.name ?? "",
        type: row.type ?? "delivery_route",
        bills: row.bills ?? 0,
        status: row.status ?? "",
        isInboundLoadCard: checkboxStates.isInboundLoadCard,
        isDeliveryManifest: checkboxStates.isDeliveryManifest,
        isDeliveryReceipts: checkboxStates.isDeliveryReceipts,
        isTrapLoadCard: checkboxStates.isTrapLoadCard,
      };
    });

  const columns = getColumns(printTableRows);

  return (
    <Dialog open onClose={onCancel} maxWidth="lg" fullWidth>
      <PrintDialogTitle
        isBulkPrinting={isBulkPrinting}
        onCancel={onCancel}
        planTableRowData={plansWithBills}
      />
      <DialogContent sx={{ padding: "0px 24px 16px 24px" }}>
        <DialogContentText
          sx={{
            fontWeight: "400",
            fontSize: "16px",
            paddingBottom: "16px",
            color: "#000000DE",
          }}
        >
          Select your print preferences
        </DialogContentText>
        <PrintTableProvider
          initialRows={printTableRows}
          setIsPrintDialogOpen={setIsPrintDialogOpen}
        >
          <PrintTable columns={columns} isBulkPrinting={isBulkPrinting} />
          <PrintDialogActions onCancel={onCancel} />
        </PrintTableProvider>
      </DialogContent>
    </Dialog>
  );
};

export default PrintDialog;
