import { SicsSuggestion } from "../../hooks/react-query/outbound/linehaulTrailers/useGetSicsSuggestion";

export const mockSicsSuggestion = {
  default: {
    associatedSics: [
      { code: "ONT", bills: 0 },
      { code: "SFO", bills: 10 },
    ],
    otherSics: ["DAL", "EWR", "LAX"],
  },
} as const satisfies Record<string, SicsSuggestion>;
