import { LaneDetails } from "../../../hooks/react-query/outbound/freightDetails/useGetLaneDetails";

export const laneDetails = {
  default: {
    destinationSicId: 56,
    destinationSic: "BOS",
    doors: [4],
    pullCount: 1,
    bills: 15,
    loadedBills: 13,
    weight: 15690,
    loadedWeight: 14066,
    pieces: 222,
    pallets: 19,
    pulls: [
      {
        plannedPullId: 37513,
        doorNumber: 4,
        destinationSic: "BOS",
        destinationSicId: 56,
        cutTime: "",
        carrierId: null,
        carrier: "",
        tripNumber: null,
        trailerNumber: "",
        trailerEquipmentId: null,
        doorType: 1,
      },
    ],
    tags: [
      {
        id: 4,
        name: "APTR",
        color: "#777DE9",
        description: "Appointment Required ",
      },
      {
        id: 81,
        name: "VC",
        color: "#AA7EA0",
        description: "Vendor Compliance",
      },
    ],
  },
} as const satisfies Record<string, LaneDetails>;
