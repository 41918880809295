import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import pluralize from "pluralize";
import { useSearchParams } from "react-router-dom";
import { useAssignShipments } from "../../../hooks/react-query/preplanning/dialogs/useAssignShipments";
import {
  AssignToRequest,
  AssignToTarget,
} from "../../../services/prePlanningService.types";
import { ServiceCenter } from "../../../types/serviceCenter.type";
import { useSections } from "../../../hooks/react-query/preplanning/useSections";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { useAllRoutesTable } from "../../../hooks/react-query/useDeliveryRoutes";
import Loading from "../../../components/shared/layout/Loading";
import { useGetInboundTraps } from "../../../hooks/react-query/preplanning/useTraps";
import { toast } from "../../../utils/snackbarHelper";
import { LoadingButton } from "../../../components/shared/LoadingButton";
import SelectTrap from "../../../components/shared/SelectTrap";
import { toastMessage } from "../../../constants/strings";
import DropdownSelect from "../../../components/shared/DropdownSelect";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import DropdownStatusItem from "../../../components/shared/DropdownStatusItem";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { SummaryContext, useActionsMenuContext } from "./ActionsMenuContext";
import ClosedStatusWarning from "./ClosedStatusWarning";

const PLAN_TYPE_PARAM = "planType";

type SelectBoxProps = {
  sic: ServiceCenter;
  date: string;
  handleTarget: (target: AssignToTarget) => void;
};

const TrapSelectBox = ({ sic, handleTarget, date }: SelectBoxProps) => {
  const { data: traps = [], isLoading } = useGetInboundTraps({
    sicId: sic.id,
    date: dateToInt(date),
  });
  const defaultOptionText = "Select a Trap";
  const enabled = traps.length > 0;
  const title = "Trap";

  if (isLoading) return <Loading />;

  return (
    <Box>
      <Typography fontSize={14} fontWeight={600} mb={1}>
        {title}
      </Typography>
      <FormControl
        sx={{ width: "100%" }}
        size="small"
        disabled={!enabled}
        required
      >
        <InputLabel id={`select-${defaultOptionText}-label`} htmlFor="trap">
          {defaultOptionText}
        </InputLabel>
        <SelectTrap
          id="trap"
          label={defaultOptionText}
          disabled={!enabled}
          title={title}
          traps={traps}
          size="small"
          onTrapChange={(event) => {
            const trapId = event.toString();
            handleTarget({
              id: trapId,
              type: "trap",
              date: dateToInt(date),
              sicId: sic.id,
            });
          }}
        />
      </FormControl>
    </Box>
  );
};

const RouteSelectBox = ({ date, sic, handleTarget }: SelectBoxProps) => {
  const { data: routes = [], isLoading } = useAllRoutesTable({
    date: dateToInt(date),
    sicId: sic.id,
  });
  const [target, setTarget] = useState("");

  if (isLoading) return <Loading />;

  return (
    <DropdownSelect
      disabled={routes.length === 0}
      title="Route"
      label="Select a Route"
      options={routes.map((route) => ({
        name: route.title,
        value: route.id,
        bills: route.bills,
        weight: route.weight,
        pallets: route.pallets,
        pieces: route.pieces,
        unit: route.shipmentsWeightUnit,
        metadata: {
          status: route.status,
        },
      }))}
      value={target}
      onChange={(event) => {
        const value = String(event.target.value);
        setTarget(value);
        handleTarget({
          id: value,
          type: "route",
          date: dateToInt(date),
          sicId: sic.id,
        });
      }}
      required
      renderOption={({
        name,
        bills,
        weight,
        pallets,
        pieces,
        unit,
        metadata,
      }) => (
        <DropdownStatusItem
          label={name}
          shipmentDetails={{ bills, weight, pallets, pieces, unit }}
          status={metadata.status}
          bgColor="green"
          color="white"
        />
      )}
    />
  );
};

const SectionSelectBox = ({ sic, handleTarget, date }: SelectBoxProps) => {
  const { data: sections = [], isLoading } = useSections(sic.id);
  const [target, setTarget] = useState("");

  if (isLoading) return <Loading />;

  return (
    <DropdownSelect
      disabled={sections.length === 0}
      title="Section"
      label="Select a Section"
      options={sections.map((section) => ({
        name: section.title,
        value: section.id,
      }))}
      value={target}
      onChange={(event) => {
        const value = String(event.target.value);
        setTarget(value);
        handleTarget({
          id: value,
          type: "section",
          date: dateToInt(date),
          sicId: sic.id,
        });
      }}
      required
    />
  );
};

type AssignToDialogProps = {
  onSuccess?: () => void;
};

export const AssignToDialog = ({ onSuccess }: AssignToDialogProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const {
    closeDialog,
    shipments,
    clearSelection,
    isUpdatingClosedRouteOrTrap,
  } = useActionsMenuContext();
  const [searchParams] = useSearchParams();

  const planType = searchParams.get(PLAN_TYPE_PARAM);

  const isNewPlanningTableDesignEnabled = useIsFeatureFlagEnabled(
    "inbound-new-planning-table-design-client"
  );
  const [date] = useDateSearchParamOrFallbackToToday();
  const planDate = dateToInt(date);

  const determineInitialAssignValue = useCallback(
    (
      isNewPlanningTableDesignEnabled: boolean,
      planType: string | null,
      shipments: SummaryContext[]
    ): string => {
      if (isNewPlanningTableDesignEnabled) {
        return planType === "delivery_route" ? "route" : "trap";
      }

      const allDeliveryRoutes = shipments.every(
        (shipment) => shipment.type === "route"
      );
      return allDeliveryRoutes ? "route" : "trap";
    },
    []
  );

  const initialAssignValue = useMemo(
    () =>
      determineInitialAssignValue(
        isNewPlanningTableDesignEnabled,
        planType,
        shipments
      ),
    [
      isNewPlanningTableDesignEnabled,
      planType,
      shipments,
      determineInitialAssignValue,
    ]
  );

  const [type, setType] = useState(initialAssignValue);
  const [target, setTarget] = useState<AssignToTarget>({
    id: "0",
    type: "none",
    date: planDate,
    sicId: serviceCenter.id,
  });

  const showClosedStatusWarning = isUpdatingClosedRouteOrTrap(target.id);

  const handleTarget = (target: AssignToTarget) => {
    setTarget(target);
  };

  const createAssignToRequest = (target: AssignToTarget): AssignToRequest => {
    const origins = shipments.map((shipment) => ({
      id: shipment.id.toString(),
      shipmentIds: shipment.shipmentIds,
      type: shipment.type,
    }));
    return {
      origins: [...origins],
      target: {
        id: target.id || "0",
        type: target.type || "none",
        date: planDate,
        sicId: serviceCenter.id,
      },
    };
  };

  const assignShipments = useAssignShipments({
    onSuccess: ({ affectedShipmentsCount }) => {
      clearSelection?.();
      if (onSuccess) onSuccess();
      toast(toastMessage.inbound.assign.success(affectedShipmentsCount), {
        variant: "success",
      });
      closeDialog();
    },
  });

  const uniqueShipmentIds = new Set(shipments.flatMap((s) => s.shipmentIds));

  const handleAssignTo = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (uniqueShipmentIds.size === 0) {
      toast(toastMessage.inbound.assign.noShipmentsToAssign, {
        variant: "warning",
      });
    } else {
      assignShipments.mutate(createAssignToRequest(target));
    }
  };

  const handleCloseDialog = () => {
    closeDialog();
    assignShipments.reset();
  };

  return (
    <Dialog open onClose={closeDialog}>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Assign {pluralize("Shipment", uniqueShipmentIds.size)}
      </DialogTitle>
      {showClosedStatusWarning && <ClosedStatusWarning />}
      {assignShipments.isPending ? (
        <>
          <DialogContent
            sx={{ mt: 3, display: "flex", justifyContent: "center" }}
          >
            <Loading />
          </DialogContent>
          <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
            <Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
            </Button>
          </DialogActions>
        </>
      ) : (
        <form onSubmit={handleAssignTo}>
          <DialogContent sx={{ width: "60vw", maxWidth: "500px" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              Assign {pluralize("shipment", uniqueShipmentIds.size, true)} to:
            </Typography>
            <FormControl sx={{ marginBottom: 2 }}>
              <RadioGroup
                aria-labelledby="assign-to-radio-buttons-group-label"
                defaultValue="trap"
                name="assign-to-radio-buttons-group"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <FormControlLabel
                  value="trap"
                  control={<Radio />}
                  label="Trap"
                />
                <FormControlLabel
                  value="route"
                  control={<Radio />}
                  label="Route"
                />
                {/** 
                 * TODO remove this comment when assigning section is available on the API
                 <FormControlLabel
                  value="section"
                  control={<Radio />}
                  label="Section"
                /> */}
              </RadioGroup>
            </FormControl>
            {type === "trap" ? (
              <TrapSelectBox
                date={date}
                sic={serviceCenter}
                handleTarget={handleTarget}
              />
            ) : type === "route" ? (
              <RouteSelectBox
                sic={serviceCenter}
                handleTarget={handleTarget}
                date={date}
              />
            ) : type === "section" ? (
              <SectionSelectBox
                date={date}
                sic={serviceCenter}
                handleTarget={handleTarget}
              />
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions sx={{ margin: "10px 20px 20px 20px" }}>
            <LoadingButton
              loadingLabel="Assigning..."
              isLoading={assignShipments.isPending}
            >
              Assign
            </LoadingButton>
            <Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};
