import { Button, ListItemIcon, MenuItem } from "@mui/material";
import { Link } from "@mui/icons-material";
import { useLocation, useSearchParams } from "react-router-dom";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";
import { buildEncodedUrl } from "../../../../utils/httpParams";
import type { PlanTableFormat } from "../../../../types/planning/plan.type";

type CopyLinkMenuItemProps = {
  routesOrTraps: PlanTableFormat[];
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
};

export const CopyLinkMenuItem = ({
  routesOrTraps,
  handleClose,
}: CopyLinkMenuItemProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const copyRoutesOrTrapsLink = () => {
    const base = `${window.location.protocol}//${window.location.host}${location.pathname}`;

    // Extract ids from the routesOrTraps array and join them
    const selection = routesOrTraps.map((item) => item.id).join(",");

    // Append the selection to the search params
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("selection", selection);

    const fullUrl = buildEncodedUrl(base, newSearchParams);

    handleClose?.();

    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        toast(toastMessage.inbound.copyLink.success, {
          variant: "success",
        });
      })
      .catch(() => {
        toast(toastMessage.inbound.copyLink.error, {
          variant: "error",
        });
      });
  };

  return (
    <MenuItem divider onClick={copyRoutesOrTrapsLink}>
      <ListItemIcon>
        <Link />
      </ListItemIcon>
      <Button sx={labelButtonStyle} disableRipple>
        Copy Link
      </Button>
    </MenuItem>
  );
};
