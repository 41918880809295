import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../utils/httpCommon";
import { apiUrls } from "../../../utils/apiUrls";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";

export type MergeIntoType = "route" | "trap";

type MergeRoutesTrapsRequest = {
  trapIdsToMerge: number[];
  routeIdsToMerge: number[];
  mergeIntoSelect: MergeIntoType;
  mergeInto: number;
  trailerEquipmentId: number | null;
  tractorEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
  doorId: number | null;
  startTime: string;
  date: number;
  sicId: number;
  customName?: string;
  presetRouteId: number;
  driverId: number | null;
};

export const useMergeRoutesTraps = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: MergeRoutesTrapsRequest) => {
      const { status } = await http.post(apiUrls.mergeRoutesAndTraps, request);
      return status;
    },
    onSettled: () => {
      queryClient.invalidateQueries(); // The invalidate logic would be highly complex and error prone, so just invalidate all queries
    },
    onSuccess: () => onSuccess?.(),
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};
