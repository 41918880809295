import React, { useCallback } from "react";
import { TableHead, TableRow, TableCell, Checkbox } from "@mui/material";
import { Column, RowData } from "./types";
import { usePrintTableContext } from "./PrintTableContext";
import { filterRows } from "./utils";

interface PrintTableHeadProps {
  columns: ReadonlyArray<Column>;
  isBulkPrinting: boolean;
}

const PrintTableHead: React.FC<PrintTableHeadProps> = ({
  columns,
  isBulkPrinting,
}) => {
  const { rows, toggleCheckboxForAll } = usePrintTableContext();

  const isAllSelected = useCallback(
    (dataIndex: keyof RowData) => {
      const filteredRows = filterRows(rows, dataIndex);
      return filteredRows.every((row) => row[dataIndex]);
    },
    [rows]
  );

  const isIndeterminate = useCallback(
    (dataIndex: keyof RowData) => {
      const filteredRows = filterRows(rows, dataIndex);
      const numberOfCheckedRows = filteredRows.filter(
        (row) => row[dataIndex]
      ).length;

      return (
        numberOfCheckedRows > 0 && numberOfCheckedRows < filteredRows.length
      );
    },
    [rows]
  );

  return (
    <TableHead
      sx={{
        "& .MuiTableCell-head": {
          color: "#000000DE",
          fontWeight: 400,
          fontSize: 12,
        },
      }}
    >
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.header}
            sx={{
              height: "3.5rem",
              padding: "16px 10px 8px 16px",
              verticalAlign: isBulkPrinting ? "middle" : "bottom",
            }}
          >
            {isBulkPrinting && column.renderAs === "checkbox" && (
              <Checkbox
                checked={isAllSelected(column.dataIndex)}
                onChange={() => toggleCheckboxForAll(column.dataIndex)}
                indeterminate={isIndeterminate(column.dataIndex)}
                inputProps={{ "aria-label": `Select all ${column.header}` }}
              />
            )}
            {column.header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default PrintTableHead;
