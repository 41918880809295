import { useState } from "react";
import { Print } from "@mui/icons-material";
import { Button, ListItemIcon, MenuItem } from "@mui/material";
import PrintDialog from "../../../../components/shared/printTable/PrintDialog";
import { toastMessage } from "../../../../constants/strings";
import {
  PrintResult,
  usePrintBulkReport,
} from "../../../../hooks/react-query/usePrint";
import { PlanTableFormat } from "../../../../types/planning/plan.type";
import { toast } from "../../../../utils/snackbarHelper";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../../hooks/useDateSearchParamOrFallbackToToday";
import { labelButtonStyle } from "../../../../components/shared/PopupMenu";
import { allStatuses } from "../../../../types/status.type";

type PlanningStatus = string;

type PrintMenuItemProps = {
  routesOrTraps: PlanTableFormat[];
  /** Omit if don't want to call it after the action */
  handleClose?: () => void;
  clearSelection?: () => void;
  isBulkPrint: boolean;
};

export const PrintMenuItem = ({
  routesOrTraps,
  handleClose,
  clearSelection = () => {},
  isBulkPrint,
}: PrintMenuItemProps) => {
  const showPrintDialog = useIsFeatureFlagEnabled(
    "inbound-planning-print-dialog-client"
  );
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  const onPrintSuccess = (result: PrintResult) => {
    handleClose?.();
    if (result.state === "Processed") {
      window.open(result.url, "_blank");
    }
  };

  const { mutate: printBulkReport } = usePrintBulkReport(onPrintSuccess);

  const handleBulkPrintClick = () => {
    const plansWithBills = routesOrTraps.filter(
      (routeOrTrap) => routeOrTrap.bills > 0
    );

    const routes = routesOrTraps.filter(
      (record) => record.type === "delivery_route"
    );

    const traps = routesOrTraps.filter(
      (record) => record.type === "delivery_trap"
    );

    if (routes.length === 0 && traps.length === 0) {
      toast(toastMessage.inbound.print.selectAtLeastOneRoute, {
        variant: "warning",
      });
      return;
    }

    if (plansWithBills.length === 0) {
      toast(toastMessage.inbound.print.noBills, {
        variant: "warning",
      });
      return;
    }

    const routeOrTrapStatus = new Set<PlanningStatus>([
      "DISP",
      "CLDV",
      "CPLN",
      "CLDK",
      "TRAP",
      "TPLN",
      "DEPT",
    ]);

    if (
      routes.some((r) => routeOrTrapStatus.has(r.status)) ||
      (traps.some((t) => routeOrTrapStatus.has(t.status)) && showPrintDialog)
    ) {
      setIsPrintDialogOpen(true);
      return;
    }

    if (routes.length > 0 || traps.length > 0) {
      printBulkReport({
        sicId: serviceCenter.id,
        planDate: selectedDate,
        cityLoadCard: {
          routes: routes
            .filter((route) => route.bills > 0)
            .map((route) => route.id),
        },
        trapLoadCard: {
          trapIds: traps
            .filter((trap) => trap.bills > 0)
            .map((trap) => trap.id),
        },
      });
    }

    clearSelection();
  };

  const handlePrint = () => {
    const rowOrTrap = routesOrTraps[0];
    if (
      showPrintDialog &&
      rowOrTrap.status in allStatuses &&
      rowOrTrap.type !== "delivery_trap"
    ) {
      setIsPrintDialogOpen(true);
      return;
    }

    if (rowOrTrap.type === "delivery_trap") {
      printBulkReport({
        sicId: serviceCenter.id,
        planDate: selectedDate,
        trapLoadCard: {
          trapIds: [rowOrTrap.id],
        },
      });
    } else {
      printBulkReport({
        sicId: serviceCenter.id,
        planDate: selectedDate,
        cityLoadCard: {
          routes: [rowOrTrap.id],
        },
      });
    }
  };

  return (
    <>
      <MenuItem onClick={isBulkPrint ? handleBulkPrintClick : handlePrint}>
        <ListItemIcon>
          <Print />
        </ListItemIcon>
        <Button sx={labelButtonStyle} disableRipple>
          Print
        </Button>
      </MenuItem>
      {isPrintDialogOpen && (
        <PrintDialog
          onCancel={() => {
            setIsPrintDialogOpen(false);
          }}
          planTableRowData={routesOrTraps}
          setIsPrintDialogOpen={setIsPrintDialogOpen}
        />
      )}
    </>
  );
};
