import {
  LoaderFunction,
  Navigate,
  RouteObject,
  generatePath,
  redirect,
} from "react-router-dom";
import { SELECTED_DATE_PARAM_KEY } from "../hooks/useDateSearchParamOrFallbackToToday";
import ShipmentsTable from "../pages/inbound/shared/ShipmentsTable";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { PlanShipmentsTable } from "../pages/inbound/inboundPlanning/tables/PlanShipmentsTable";
import { useIsFeatureFlagEnabled } from "../featureFlags/useIsFeatureFlagEnabled";

const redirectWithSelectedDate =
  (path: string): LoaderFunction =>
  ({ request }) => {
    const url = new URL(request.url);
    const selectedDate = url.searchParams.get(SELECTED_DATE_PARAM_KEY);

    return selectedDate
      ? redirect(generatePath(path, { date: selectedDate }))
      : redirect(path);
  };

const inboundRoutesWithMapLink: RouteObject[] = [
  {
    path: "inbound",
    lazy: () => import("../pages/inbound/InboundLayout"),
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <Navigate to="unplanned-freight" replace /> },
      {
        path: "pre-planning",
        lazy: () => import("../pages/inbound/prePlanning/PrePlanning"),
        errorElement: <ErrorBoundary />,
        children: [
          { index: true, loader: redirectWithSelectedDate("appointments") },
          {
            path: "appointments",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import(
                "../pages/inbound/prePlanning/tables/appointments/Appointments"
              ),
          },
          {
            path: "appointments/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="appointments"
                showPresetRouteColumn
                haveContextMenu
                defaultSort={[
                  {
                    id: "presetRouteName",
                    desc: false,
                  },
                ]}
              />
            ),
          },
          {
            path: "traps",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/prePlanning/tables/traps/Traps"),
          },
          {
            path: "traps/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="traps"
                showPresetRouteColumn
                isDraggable
                haveContextMenu
                defaultSort={[
                  {
                    id: "presetRouteName",
                    desc: false,
                  },
                ]}
              />
            ),
          },
          {
            path: "routes",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/prePlanning/tables/allRoutes/AllRoutes"),
          },
          {
            path: "routes/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="routes"
                isDraggable
                haveContextMenu
                defaultSort={[
                  {
                    id: "consignee",
                    desc: false,
                  },
                ]}
              />
            ),
          },
          {
            path: "sections",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/prePlanning/tables/sections/Sections"),
          },
          {
            path: "sections/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="sections"
                showPresetRouteColumn
                isDraggable
                haveContextMenu
                defaultSort={[
                  {
                    id: "presetRouteName",
                    desc: false,
                  },
                ]}
              />
            ),
          },
        ],
      },
      {
        path: "unplanned-freight",
        errorElement: <ErrorBoundary />,
        lazy: () =>
          import("../pages/inbound/unplannedFreight/UnplannedFreight"),
      },
      {
        lazy: () =>
          import("../pages/inbound/inboundPlanning/InboundPlanningLayout"),
        errorElement: <ErrorBoundary />,
        children: [
          { index: true, loader: redirectWithSelectedDate("planning") },
          {
            path: "planning",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/inboundPlanning/InboundPlanning"),
            children: [
              { index: true, element: <Navigate to="list" replace /> },
              {
                path: "list",
                errorElement: <ErrorBoundary />,
                lazy: () =>
                  import("../pages/inbound/inboundPlanning/InboundListPage"),
              },
              {
                path: "grid",
                errorElement: <ErrorBoundary />,
                lazy: () =>
                  import("../pages/inbound/inboundPlanning/InboundGridPage"),
              },
              {
                path: "map",
                errorElement: <ErrorBoundary />,
                lazy: () =>
                  import("../pages/inbound/inboundPlanning/InboundMapPage"),
              },
            ],
          },
          {
            path: "planning/list/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: <PlanShipmentsTable />,
          },
        ],
      },
      {
        path: "dock-manager",
        errorElement: <ErrorBoundary />,
        lazy: () =>
          import("../pages/inbound/inboundDockManager/InboundDockManager"),
      },
    ],
  },
];
const oldInboundRoutes: RouteObject[] = [
  {
    path: "inbound",
    lazy: () => import("../pages/inbound/InboundLayout"),
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <Navigate to="unplanned-freight" replace /> },
      {
        path: "pre-planning",
        lazy: () => import("../pages/inbound/prePlanning/PrePlanning"),
        errorElement: <ErrorBoundary />,
        children: [
          { index: true, loader: redirectWithSelectedDate("appointments") },
          {
            path: "appointments",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import(
                "../pages/inbound/prePlanning/tables/appointments/Appointments"
              ),
          },
          {
            path: "appointments/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="appointments"
                showPresetRouteColumn
                haveContextMenu
                defaultSort={[
                  {
                    id: "presetRouteName",
                    desc: false,
                  },
                ]}
              />
            ),
          },
          {
            path: "traps",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/prePlanning/tables/traps/Traps"),
          },
          {
            path: "traps/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="traps"
                showPresetRouteColumn
                isDraggable
                haveContextMenu
                defaultSort={[
                  {
                    id: "presetRouteName",
                    desc: false,
                  },
                ]}
              />
            ),
          },
          {
            path: "routes",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/prePlanning/tables/allRoutes/AllRoutes"),
          },
          {
            path: "routes/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="routes"
                isDraggable
                haveContextMenu
                defaultSort={[
                  {
                    id: "consignee",
                    desc: false,
                  },
                ]}
              />
            ),
          },
          {
            path: "sections",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/prePlanning/tables/sections/Sections"),
          },
          {
            path: "sections/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: (
              <ShipmentsTable
                rowSelectionMode="checkbox"
                view="sections"
                showPresetRouteColumn
                isDraggable
                haveContextMenu
                defaultSort={[
                  {
                    id: "presetRouteName",
                    desc: false,
                  },
                ]}
              />
            ),
          },
        ],
      },
      {
        path: "unplanned-freight",
        errorElement: <ErrorBoundary />,
        lazy: () =>
          import("../pages/inbound/unplannedFreight/UnplannedFreight"),
      },
      {
        lazy: () =>
          import("../pages/inbound/inboundPlanning/InboundPlanningLayout"),
        errorElement: <ErrorBoundary />,
        children: [
          { index: true, loader: redirectWithSelectedDate("planning") },
          {
            path: "planning",
            errorElement: <ErrorBoundary />,
            lazy: () =>
              import("../pages/inbound/inboundPlanning/InboundPlanning"),
          },
          {
            path: "planning/:id/shipments",
            errorElement: <ErrorBoundary />,
            element: <PlanShipmentsTable />,
          },
        ],
      },
      {
        path: "dock-manager",
        errorElement: <ErrorBoundary />,
        lazy: () =>
          import("../pages/inbound/inboundDockManager/InboundDockManager"),
      },
    ],
  },
];

const getInboundRoutes = (
  isMapViewEnabled: boolean,
  isNewPlanningTableDesignEnabled: boolean
): RouteObject[] =>
  isNewPlanningTableDesignEnabled && isMapViewEnabled
    ? inboundRoutesWithMapLink
    : oldInboundRoutes;

export const useInboundRoutes = (): RouteObject[] => {
  const isMapViewEnabled = useIsFeatureFlagEnabled(
    "inbound-planning-map-link-client"
  );

  const isNewPlanningTableDesignEnabled = useIsFeatureFlagEnabled(
    "inbound-new-planning-table-design-client"
  );

  return getInboundRoutes(isMapViewEnabled, isNewPlanningTableDesignEnabled);
};
