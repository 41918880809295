import dayjs from "dayjs";
import { Entry } from "../../hooks/react-query/linehaul/useMasterScheduleEntry";
import { getEndOfWeekUtc } from "../../utils/dateTimeHelper";
import { DaylightDateFormat } from "../../constants/DaylightDateFormat";

export const mockEntryDetails = {
  default: {
    loadPairScheduleId: 1,
    carrier: {
      id: 1,
      name: "Avileno Trucking Inc.",
      code: "M123",
    },
    serviceType: {
      id: 1,
      name: "Dedicated",
    },
    effectiveToUtc: getEndOfWeekUtc().format(
      DaylightDateFormat.ISO_DATE_TIME_WITHOUT_TIMEZONE
    ),
    loads: [
      {
        id: 1,
        loadingDate: dayjs()
          .day(4)
          .format(DaylightDateFormat.ISO_DATE_TIME_WITHOUT_TIMEZONE),
        pickupDate: dayjs()
          .day(5)
          .hour(16)
          .minute(30)
          .format(DaylightDateFormat.ISO_DATE_TIME_WITHOUT_TIMEZONE),
        originSic: {
          id: 1,
          code: "ONT",
          name: "Ontario",
        },
        destinationSic: {
          id: 2,
          code: "EWR",
          name: "Newark",
        },
        bookingNumber: "Harold",
        notes: "Start test note",
        employeeDrivers: [],
        stops: [],
        deadheads: [],
      },
      {
        id: 2,
        loadingDate: dayjs()
          .day(3)
          .format(DaylightDateFormat.ISO_DATE_TIME_WITHOUT_TIMEZONE),
        pickupDate: dayjs()
          .day(4)
          .hour(12)
          .minute(34)
          .format(DaylightDateFormat.ISO_DATE_TIME_WITHOUT_TIMEZONE),
        originSic: {
          id: 2,
          code: "EWR",
          name: "Newark",
        },
        destinationSic: {
          id: 1,
          code: "ONT",
          name: "Ontario",
        },
        bookingNumber: "Jon",
        notes: "Return test note",
        employeeDrivers: [],
        stops: [],
        deadheads: [],
      },
    ],
  },
  withStopAndDeadhead: {
    loadPairScheduleId: 3084634,
    loads: [
      {
        id: 3048936,
        employeeDrivers: [],
        loadingDate: "2024-12-19T00:00:00",
        pickupDate: "2024-12-19T00:00:00",
        originSic: {
          id: 2,
          code: "EWR",
          name: "Newark",
        },
        destinationSic: {
          id: 1,
          code: "ONT",
          name: "Ontario",
        },
        bookingNumber: "123123123",
        stops: [2],
        deadheads: [{ originId: 1, destinationId: 155 }],
        notes: "note",
      },
    ],
    carrier: {
      id: 95128,
      name: "COVENANT TRANSPORT INC",
      code: "L004",
    },
    serviceType: {
      id: 4,
      name: "1-way",
    },
    effectiveToUtc: "2024-12-21T23:59:59.999",
  },
} as const satisfies Record<string, Entry>;
