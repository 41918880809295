import dayjs from "dayjs";
import { z } from "zod";

type UsePlanStartDateArgs = {
  startTime: string | null;
  planDate: number;
  timeZone: string;
  keepLocalTime: boolean;
};
/*
  The purpose of usePlanStartDate will do followings

  1. StartTime will match to PlanDate
  2. Construct dayjs object to display the consistent time regardless of the device's timezone.
   
  Let's say the goal is to create a plan for 4/10 in DAL at 8 am given following conditions
  - I'm in LA
  - Working on a plan for DAL
  - Today: 4/9
  - Plan Date: 4/10

  During the creation
  I will select 8 am in the dropdown menu. This means I meant 4/10 8am in DAL (UTC 1pm), not 4/9 8am in LA (UTC 3pm).
  
  When we send it to the backend, we need to make sure to use keepLocal: true so that 8am is used.
  Also, we need to make sure the start date is set to 4/10, not 4/9. usePlanStartDate will handle this.

  Next, when we retrieve the data from the backend, data is coming back as 8am offset -5 (which is 1pm UTC).
  Since this time is 6am in LA, we need to set keepLocalTime: false to display 8am (DAL time) 
*/
export function usePlanStartDate({
  startTime,
  planDate,
  timeZone,
  keepLocalTime,
}: UsePlanStartDateArgs) {
  if (startTime === null) {
    return dayjs();
  }
  const { year, month, date } = parsePlanDate(planDate);

  return (
    dayjs(startTime)
      .tz(timeZone, keepLocalTime)
      // A user can work on future date's plan.
      // Therefore startTime needs to be adjusted to the planDate.
      // ex. Today is 4/9. But you want to set 4/10 if your plan is for 4/10
      .set("year", year)
      .set("month", month)
      .set("date", date)
  );
}

function parsePlanDate(planDate: number) {
  const planDateStr = z.number().int().positive().parse(planDate).toString(); // YYYYMMDD
  z.string().length(8).parse(planDateStr);
  return {
    year: parseInt(planDateStr.substring(0, 4), 10),
    month: z
      .number()
      .min(0)
      .max(11)
      .parse(parseInt(planDateStr.substring(4, 6), 10) - 1),
    date: z
      .number()
      .min(1)
      .max(31)
      .parse(parseInt(planDateStr.substring(6, 8), 10)),
  };
}
