import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  forwardRef,
  memo,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Table as MuiTable,
  TableContainer,
  Paper,
  SxProps,
} from "@mui/material";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import FeedbackIndicator from "../FeedbackIndicator";
import Loading from "../layout/Loading";
import { Checkbox } from "../Checkbox";
import { FlexColumn } from "../layout/Flex";
import { useClearSelectionWhenServiceCenterChanges } from "../../../hooks/useClearSelectionWhenServiceCenterChanges";
import TableCaption from "../TableCaption";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";
import { SelectableProps } from "./Selectable.types";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TablePagination from "./TablePagination";
import { ErrorMap } from "./table.types";

type CommonProps<TData> = {
  /** table reference */
  ref?: Ref<TableRef>;
  /** table data */
  data?: TData[];
  /** columns definition array */
  columns: ColumnDef<TData, any>[];
  /** columns before the definition */
  preColumns?: ColumnDef<TData, any>[];
  /** Table caption for a11y and testing purposes. The caption is visually hidden. */
  caption: string;
  /** display a loading indicator instead of the table when true */
  isLoading?: boolean;
  /** Rows with errors. */
  rowErrors?: ErrorMap;
  /** custom styles for Table Container */
  styles?: SxProps;
};

type DragNDropProps<TData> =
  | {
      /** id of the droppable table */
      droppableTableId: string;
      /** id of the droppable row */
      droppableRowId?: never;
    }
  | {
      /** id of the droppable table */
      droppableTableId?: never;
      /** id of the droppable row */
      droppableRowId: (row: Row<TData>) => string;
    }
  | {
      /** id of the droppable table */
      droppableTableId?: never;
      /** id of the droppable row */
      droppableRowId?: never;
    };

type ContextMenuProps<TData> = {
  /** context menu */
  contextMenuActions?: (row?: TData) => ContextMenuAction[];
};

type SortableProps = {
  /** default sort array */
  defaultSort?: SortingState;
};

export type TableProps<TData> = CommonProps<TData> &
  ContextMenuProps<TData> &
  DragNDropProps<TData> &
  SelectableProps<TData> &
  SortableProps & {
    /** function to get the scroll position of TableContainer */
    onContainerScroll?: (value: number) => void;
  };

export type TableRef = {
  clearSelection: () => void;
  setContainerScrollTop: (value: number) => void;
};

export const ACTION_COLUMN_MAX_SIZE = 48;

/** Abstracts all table business logic. */
const Table = <TData,>(
  {
    data,
    columns,
    preColumns = [],
    caption,
    styles,
    droppableTableId,
    droppableRowId,
    rowSelectionMode = "unselectable",
    isLoading = false,
    contextMenuActions,
    onRowSelection,
    getRowId,
    defaultSort = [],
    onContainerScroll,
    rowErrors,
  }: TableProps<TData>,
  ref?: Ref<TableRef>
) => {
  const { selection } = useTableSelectionParam();
  const initialRender = useRef<boolean>(selection.length > 0);

  const formatInitialSelection = useMemo(() => {
    const newSelection: RowSelectionState = {};
    selection
      .filter((x) => x)
      .forEach((id) => {
        newSelection[id] = true;
      });
    return newSelection;
  }, [selection]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    formatInitialSelection
  );

  const [sortBy, setSortBy] = useState<SortingState>(defaultSort);
  const containerRef = useRef<HTMLDivElement>(null);

  const assembleColumns = useMemo(
    () => [
      {
        id: "select",
        size: ACTION_COLUMN_MAX_SIZE,
        maxSize: ACTION_COLUMN_MAX_SIZE,
        header: ({ table }) =>
          Checkbox({
            name: "selectAllRows",
            ariaLabel: "Select all rows",
            checked: table.getIsAllRowsSelected(),
            disabled: false,
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }),
        cell: ({ row }) =>
          Checkbox({
            name: "selectRow",
            ariaLabel: `Trailer ${row.id}`,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler(),
          }),
      },
      ...preColumns,
      ...columns,
    ],
    [columns, preColumns]
  );

  const table = useReactTable<TData>({
    data: data ?? [],
    columns:
      rowSelectionMode === "checkbox" ||
      rowSelectionMode === "checkbox-and-row-click"
        ? assembleColumns
        : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: sortBy,
      rowSelection,
    },
    defaultColumn: {
      minSize: ACTION_COLUMN_MAX_SIZE,
      size: ACTION_COLUMN_MAX_SIZE,
    },
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    onSortingChange: setSortBy,
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: rowSelectionMode !== "unselectable",
    enableMultiRowSelection: rowSelectionMode !== "unselectable",
    getRowId,
    onRowSelectionChange: (updater) => {
      // On the first render, use the initial rowSelection state from the URL or a pre-defined value to preserve the selection.
      // For subsequent renders, allow the updater function to modify the rowSelection state dynamically.
      const result: RowSelectionState =
        typeof updater === "function"
          ? updater(rowSelection)
          : initialRender.current
            ? rowSelection
            : updater;
      setRowSelection(result);
      onRowSelection && onRowSelection(Object.keys(result).map(String));
      initialRender.current = false;
    },
  });

  const clearSelection = () => {
    table.resetRowSelection(true);
  };

  useClearSelectionWhenServiceCenterChanges(() => clearSelection());

  useImperativeHandle(ref, () => ({
    clearSelection: () => clearSelection(),
    setContainerScrollTop(value) {
      containerRef.current?.scrollTo({
        top: value,
      });
    },
  }));

  if (isLoading) {
    return <Loading label={caption} />;
  }

  if (data && data.length === 0) {
    return (
      <>
        <TableContainer sx={styles} component={Paper}>
          <MuiTable stickyHeader size="small">
            <TableCaption>{caption}</TableCaption>
            <TableHeader table={table} />
          </MuiTable>
        </TableContainer>
        <FeedbackIndicator />
      </>
    );
  }

  return (
    <FlexColumn>
      <TableContainer
        ref={containerRef}
        component={Paper}
        sx={{ ...styles, flexGrow: 1 }}
        onScroll={(e) => {
          if (e.target instanceof HTMLDivElement) {
            onContainerScroll?.(e.target.scrollTop);
          }
        }}
      >
        <MuiTable stickyHeader size="small">
          <TableCaption>{caption}</TableCaption>
          <TableHeader table={table} />
          <TableBody
            table={table}
            rowSelectionMode={rowSelectionMode}
            contextMenuActions={contextMenuActions}
            droppableTableId={droppableTableId}
            droppableRowId={droppableRowId}
            rowErrors={rowErrors}
          />
        </MuiTable>
        <TablePagination table={table} data={data} />
      </TableContainer>
    </FlexColumn>
  );
};

Table.displayName = "Table";

export default memo(forwardRef(Table)) as <TData>(
  props: TableProps<TData>,
  ref: Ref<TableRef>
) => React.JSX.Element;
