// Map helper for auto-completion and safety when specifying the fill color on plain, unwrapped MUI icons
export const iconFillColors = {
  primaryDark: "#014A80", // AppTheme.palette.primary.dark
  darkGray: "#00000091", // AppTheme.palette.grey[600]
  gray: "gray", // AppTheme.palette.action.active
} as const;

export type IconSize = 12 | 16 | 20 | 24;

export interface IconProps {
  /** Icon fillColor */
  fill?: (typeof iconFillColors)[keyof typeof iconFillColors];

  /** Icon width and height in pixels */
  size?: IconSize;
  style?: React.CSSProperties;
}
