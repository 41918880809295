export const handleAll = (param: string) =>
  param.toLowerCase() === "all" ? "" : param;

export const buildEncodedUrl = (
  base: string,
  searchParams?: URLSearchParams
): string => {
  const url = new URL(base);

  if (searchParams) {
    const params = new URLSearchParams(searchParams);
    url.search = params.toString();
  }

  return url.toString();
};
