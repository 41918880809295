import dayjs from "dayjs";
import {
  DaySummaryV2,
  LoadBoardSchedule,
  LoadBoardShipment,
} from "../../hooks/react-query/linehaul/useLoadBoard";
import { TripDetail } from "../../hooks/react-query/linehaul/useTrip";

const mockoadboardSchedules: LoadBoardSchedule[] = [
  {
    scheduleId: 3022866,
    loadPairScheduleId: 3082663,
    bookingNumber: "TEAM H",
    bookingStatus: "Confirmed",
    tripStatus: "PLAN",
    originSicCode: "DAL",
    destinationSicCode: "EWR",
    loadingDate: dayjs("2024-09-26T00:00:00"),
    pickupDate: dayjs("2024-09-27T03:00:00"),
    gateCutDate: dayjs("2024-09-22T00:00:00"),
    serviceType: "Dedicated",
    carrierName: "COVENANT TRANSPORT INC",
    carrierCode: "L004",
    tripNumber: 3098840,
    trailerNumber: "",
    deadheads: [],
    stops: [],
    tripPriority: 1,
    totalRevenue: 0,
    totalCost: 0,
    netLinehaul: 0.0,
    isPublished: true,
    isCancelled: false,
    loadType: "Start",
    hasLoadPair: true,
    tonu: 0,
    employeeDrivers: [],
    carrierRatePerMile: 0,
  },
  {
    scheduleId: 3022165,
    loadPairScheduleId: 3081663,
    bookingNumber: "TEAM H",
    bookingStatus: "Confirmed",
    tripStatus: "LDDK",
    originSicCode: "DAL",
    destinationSicCode: "EWR",
    loadingDate: dayjs("2024-09-26T00:00:00"),
    pickupDate: dayjs("2024-09-27T03:00:00"),
    gateCutDate: dayjs("2024-09-22T00:00:00"),
    serviceType: "Dedicated",
    carrierName: "NORMAL TRANSPORT INC",
    carrierCode: "L004",
    tripNumber: 3098812,
    trailerNumber: "",
    deadheads: [],
    stops: [],
    tripPriority: 2,
    totalRevenue: 0,
    totalCost: 0,
    netLinehaul: 0.0,
    isPublished: true,
    isCancelled: false,
    loadType: "Start",
    hasLoadPair: true,
    tonu: 0,
    employeeDrivers: [],
    carrierRatePerMile: 0,
  },
  {
    scheduleId: 3322865,
    loadPairScheduleId: 3082763,
    bookingNumber: "TEAM H",
    bookingStatus: "Confirmed",
    tripStatus: "CLOS",
    originSicCode: "DAL",
    destinationSicCode: "EWR",
    loadingDate: dayjs("2024-09-26T00:00:00"),
    pickupDate: dayjs("2024-09-27T03:00:00"),
    gateCutDate: dayjs("2024-09-22T00:00:00"),
    serviceType: "Dedicated",
    carrierName: "PERFECT TRANSPORT INC",
    carrierCode: "L004",
    tripNumber: 3098815,
    trailerNumber: "",
    deadheads: [],
    stops: [],
    tripPriority: 3,
    totalRevenue: 0,
    totalCost: 0,
    netLinehaul: 0.0,
    isPublished: true,
    isCancelled: false,
    loadType: "Start",
    hasLoadPair: true,
    tonu: 0,
    employeeDrivers: [],
    carrierRatePerMile: 0,
  },
  {
    scheduleId: 3022365,
    loadPairScheduleId: 2082663,
    bookingNumber: "TEAM H",
    bookingStatus: "Confirmed",
    tripStatus: "ENRT",
    originSicCode: "DAL",
    destinationSicCode: "EWR",
    loadingDate: dayjs("2024-09-26T00:00:00"),
    pickupDate: dayjs("2024-09-27T03:00:00"),
    gateCutDate: dayjs("2024-09-22T00:00:00"),
    serviceType: "Dedicated",
    carrierName: "RANDOM TRANSPORT INC",
    carrierCode: "L004",
    tripNumber: 3098813,
    trailerNumber: "",
    deadheads: [],
    stops: [],
    tripPriority: 4,
    totalRevenue: 0,
    totalCost: 0,
    netLinehaul: 0.0,
    isPublished: true,
    isCancelled: false,
    loadType: "Start",
    hasLoadPair: true,
    tonu: 0,
    employeeDrivers: [],
    carrierRatePerMile: 0,
  },
  {
    scheduleId: 3022864,
    loadPairScheduleId: 3092663,
    bookingNumber: "TEAM H",
    bookingStatus: "Confirmed",
    tripStatus: "PLAN",
    originSicCode: "DAL",
    destinationSicCode: "EWR",
    loadingDate: dayjs("2024-09-26T00:00:00"),
    pickupDate: dayjs("2024-09-27T03:00:00"),
    gateCutDate: dayjs("2024-09-22T00:00:00"),
    serviceType: "Dedicated",
    carrierName: "COVENANT TRANSPORT INC",
    carrierCode: "L004",
    tripNumber: 3098840,
    trailerNumber: "",
    deadheads: [],
    stops: [],
    tripPriority: 1,
    totalRevenue: 0,
    totalCost: 0,
    netLinehaul: 0.0,
    isPublished: false,
    isCancelled: false,
    loadType: "Start",
    hasLoadPair: true,
    tonu: 0,
    employeeDrivers: [],
    carrierRatePerMile: 0,
  },
];

const shipments: LoadBoardShipment[] = [
  {
    shipmentId: 132443834,
    proNumber: "147363543",
    tags: ["APTR", "DTCL", "LG"],
    serviceDue: null,
    pickupDate: dayjs("2024-08-29T04:00:00"),
    tripNumber: 3025304,
    shipperName: "NATIONAL PUBLIC SEATING @ECONOMY FRT",
    shipperZipCode: "07014",
    consigneeName: "DELTA PARTY RENTAL",
    consigneeZipCode: "38732",
    weight: 460,
    pallets: 1,
    pieces: 0,
    revenue: 351.05,
    originSicCode: "EWR",
    destinationSicCode: "ATL",
    currentSicCode: "ATM",
    originSic: {
      id: 1,
      code: "EWR",
      ownership: "V",
      lhRegionId: 4,
      timeZone: null,
    },
    destinationSic: {
      id: 18,
      code: "ATL",
      ownership: "V",
      lhRegionId: null,
      timeZone: null,
    },
    currentSic: {
      id: 164,
      code: "ATM",
      ownership: "V",
      lhRegionId: null,
      timeZone: null,
    },
    status: "CARV",
    timeOnDock: null,
  },
  {
    shipmentId: 132439221,
    proNumber: "148398555",
    tags: ["APTR", "RESI", "LG", "DTCL"],
    serviceDue: null,
    pickupDate: dayjs("2024-08-29T04:00:00"),
    tripNumber: 3025304,
    shipperName: "CURATE EAST",
    shipperZipCode: "11040",
    consigneeName: "NICHOLAS HERGA",
    consigneeZipCode: "31411",
    weight: 150,
    pallets: 1,
    pieces: 0,
    revenue: 342.92,
    originSicCode: "EWR",
    destinationSicCode: "ATL",
    currentSicCode: "ATM",
    originSic: {
      id: 1,
      code: "EWR",
      ownership: "V",
      lhRegionId: 4,
      timeZone: null,
    },
    destinationSic: {
      id: 18,
      code: "ATL",
      ownership: "V",
      lhRegionId: null,
      timeZone: null,
    },
    currentSic: {
      id: 164,
      code: "ATM",
      ownership: "V",
      lhRegionId: null,
      timeZone: null,
    },
    status: "CARV",
    timeOnDock: null,
  },
  {
    shipmentId: 132439289,
    proNumber: "148398589",
    tags: ["APTR", "RESI", "LG", "DTCL"],
    serviceDue: null,
    pickupDate: dayjs("2024-08-29T04:00:00"),
    tripNumber: 3025304,
    shipperName: "TEST EAST",
    shipperZipCode: "11040",
    consigneeName: "NICHOLAS HERGA",
    consigneeZipCode: "31411",
    weight: 150,
    pallets: 1,
    pieces: 0,
    revenue: 342.92,
    originSicCode: "EWR",
    destinationSicCode: "ATL",
    currentSicCode: "ATM",
    originSic: {
      id: 1,
      code: "EWR",
      ownership: "V",
      lhRegionId: 4,
      timeZone: null,
    },
    destinationSic: {
      id: 18,
      code: "ATL",
      ownership: "V",
      lhRegionId: null,
      timeZone: null,
    },
    currentSic: {
      id: 164,
      code: "ATM",
      ownership: "V",
      lhRegionId: null,
      timeZone: null,
    },
    status: "CARV",
    timeOnDock: null,
  },
];

export const mockDaySummaryV2 = {
  default: [
    {
      originSic: {
        id: 103,
        code: "ATB-SEFL",
        ownership: "V",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      destinationSic: {
        id: 103,
        code: "ATM",
        ownership: "V",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      tripsCount: 5,
      totalPulls: 9,
      pendingSchedulesCount: 10,
      confirmedSchedulesCount: 11,
      tripsWithStopsCount: 2,
      cancelledSchedulesCount: 9,
      schedulesCount: 88,
      totalBills: 998,
      totalWeight: 1234,
      loadedBills: 20,
      loadedWeight: 17294,
      ucBillsCount: 1,
      ccBillsCount: 0,
      volBillsCount: 0,
      ltlBillsCount: 15,
      apptBillsCount: 4,
      ucWeight: 690,
      ccWeight: 0,
      volWeight: 0,
      ltlWeight: 15338,
      apptWeight: 1266,
      palletsCount: 37,
      piecesCount: 21,
      revenue: 10656.51,
      gateCutDay: 5,
      gateCutTime: "09:15:00",
      schedules: [
        {
          scheduleId: 3020996,
          loadPairScheduleId: 3081257,
          bookingNumber: "JOSE/ARMANDO",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92250,
            name: "AVILENO TRUCKING LLC",
            code: "M163",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T09:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 43021191,
          loadPairScheduleId: 3081382,
          bookingNumber: "OLIVE",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "D T ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: true,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "CarrierCancelled",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 30215237,
          loadPairScheduleId: 3081412,
          bookingNumber: "5942325",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },
          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: mockoadboardSchedules,
      shipments: [
        ...shipments,
        ...shipments.map((s, idx) => ({
          ...s,
          proNumber: s.proNumber + 1 + idx,
          shipmentId: s.shipmentId + idx,
          status: "ENRT",
        })),
      ],
      mergedLanes: [],
      mergedOrigin: null,
    },
    {
      originSic: {
        id: 103,
        code: "ONT",
        ownership: "C",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      destinationSic: {
        id: 103,
        code: "LAX",
        ownership: "C",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      tripsCount: 0,
      totalPulls: 9,
      pendingSchedulesCount: 0,
      confirmedSchedulesCount: 10,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 0,
      totalBills: 0,
      totalWeight: 0,
      loadedBills: 0,
      loadedWeight: 0,
      ucBillsCount: 0,
      ccBillsCount: 0,
      volBillsCount: 0,
      ltlBillsCount: 0,
      apptBillsCount: 0,
      ucWeight: 0,
      ccWeight: 0,
      volWeight: 0,
      ltlWeight: 0,
      apptWeight: 0,
      palletsCount: 0,
      piecesCount: 0,
      revenue: 0,
      gateCutDay: 5,
      gateCutTime: "09:15:00",
      schedules: [
        {
          scheduleId: 3020999,
          loadPairScheduleId: 3081257,
          bookingNumber: "JOSE/ARMANDO",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92250,
            name: "AVILENO TRUCKING LLC",
            code: "M163",
            isActive: true,
          },
          gateCutDay: 2,
          gateCutTime: "22:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T09:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021191,
          loadPairScheduleId: 3081382,
          bookingNumber: "OLIVE",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "D T ENGLAND INC",
            code: "L027",
            isActive: true,
          },
          gateCutDay: 4,
          gateCutTime: "13:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: true,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "CarrierCancelled",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 302133237,
          loadPairScheduleId: 3081412,
          bookingNumber: "5942325",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },
          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: [],
      shipments,
      mergedLanes: [],
      mergedOrigin: null,
    },
    {
      originSic: {
        id: 164,
        code: "ATM",
        ownership: "C",
        lhRegionId: 3,
        timeZone: "America/New_York",
      },
      destinationSic: null,
      tripsCount: 100199,
      totalPulls: 9,
      pendingSchedulesCount: 0,
      confirmedSchedulesCount: 3,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 6,
      totalBills: 309,
      totalWeight: 100777,
      loadedBills: 66,
      loadedWeight: 41921,
      ucBillsCount: 1,
      ccBillsCount: 1,
      volBillsCount: 3,
      ltlBillsCount: 44,
      apptBillsCount: 21,
      ucWeight: 1000,
      ccWeight: 0,
      volWeight: 0,
      ltlWeight: 30597,
      apptWeight: 10324,
      palletsCount: 72,
      piecesCount: 150,
      revenue: 26381.21,
      gateCutDay: null,
      gateCutTime: null,
      schedules: [
        {
          scheduleId: 3020813,
          loadPairScheduleId: 3081101,
          bookingNumber: "56642725",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91404,
            name: "CRST VAN EXPEDITED INC",
            code: "L028",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATM",
          destinationSicCode: "HTX",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T09:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020979,
          loadPairScheduleId: 3081249,
          bookingNumber: "DARRYL/XAVIER",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92277,
            name: "VBZ GROUP CORP",
            code: "M124",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATM",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021046,
          loadPairScheduleId: 3081295,
          bookingNumber: "RYAN/VICTOR",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92278,
            name: "MARSA TRANSPORTATION INC",
            code: "M199",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATM",
          destinationSicCode: "DAL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021118,
          loadPairScheduleId: 3081340,
          bookingNumber: "LAKHVIR/GURPREET",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92243,
            name: "J2L EXPRESS LLC",
            code: "M223",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATM",
          destinationSicCode: "ORH-RXIC",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021137,
          loadPairScheduleId: 3081351,
          bookingNumber: "TONY/ANDY",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92195,
            name: "LEBONMEX INC",
            code: "M244",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATM",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: true,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T03:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021276,
          loadPairScheduleId: 3081446,
          bookingNumber: "5942312",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATM",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T23:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: [],
      shipments: [],
      mergedLanes: [],
      mergedOrigin: null,
    },
    {
      originSic: {
        id: 105,
        code: "BDL-RXIC",
        ownership: "V",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      destinationSic: null,
      tripsCount: 0,
      totalPulls: 9,
      pendingSchedulesCount: 0,
      confirmedSchedulesCount: 3,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 0,
      totalBills: 0,
      totalWeight: 0,
      loadedBills: 5,
      loadedWeight: 2467,
      ucBillsCount: 0,
      ccBillsCount: 1,
      volBillsCount: 0,
      ltlBillsCount: 2,
      apptBillsCount: 2,
      ucWeight: 0,
      ccWeight: 218,
      volWeight: 0,
      ltlWeight: 624,
      apptWeight: 1625,
      palletsCount: 5,
      piecesCount: 0,
      revenue: 7257.98,
      gateCutDay: null,
      gateCutTime: null,
      schedules: [],
      loadboardSchedules: [],
      shipments: [],
      mergedLanes: [],
      mergedOrigin: null,
    },
    {
      originSic: {
        id: 4,
        code: "BOS",
        ownership: "V",
        lhRegionId: 4,
        timeZone: "America/New_York",
      },
      destinationSic: null,
      tripsCount: 0,
      totalPulls: 9,
      pendingSchedulesCount: 0,
      confirmedSchedulesCount: 3,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 3,
      totalBills: 0,
      totalWeight: 0,
      loadedBills: 0,
      loadedWeight: 0,
      ucBillsCount: 0,
      ccBillsCount: 0,
      volBillsCount: 0,
      ltlBillsCount: 0,
      apptBillsCount: 0,
      ucWeight: 0,
      ccWeight: 0,
      volWeight: 0,
      ltlWeight: 0,
      apptWeight: 0,
      palletsCount: 0,
      piecesCount: 0,
      revenue: 0,
      gateCutDay: null,
      gateCutTime: null,
      schedules: [
        {
          scheduleId: 3020848,
          loadPairScheduleId: 3081134,
          bookingNumber: "090624-2",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109395,
            name: "IAN TRANSPORTATION LOGISTICS LLC",
            code: "L322",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "BOS",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T21:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020852,
          loadPairScheduleId: 3081138,
          bookingNumber: "090624-3",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109395,
            name: "IAN TRANSPORTATION LOGISTICS LLC",
            code: "L322",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "BOS",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021056,
          loadPairScheduleId: 3081300,
          bookingNumber: "KEVIN/JOHN",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92278,
            name: "MARSA TRANSPORTATION INC",
            code: "M199",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "BOS",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T11:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: [],
      shipments: [],
      mergedLanes: [],
      mergedOrigin: null,
    },
    {
      originSic: {
        id: 114,
        code: "DAL",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      destinationSic: null,
      tripsCount: 19,
      totalPulls: 9,
      pendingSchedulesCount: 2,
      confirmedSchedulesCount: 2,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 2,
      totalBills: 570,
      totalWeight: 570000,
      loadedBills: 128,
      loadedWeight: 132772,
      ucBillsCount: 0,
      ccBillsCount: 0,
      volBillsCount: 1,
      ltlBillsCount: 94,
      apptBillsCount: 33,
      ucWeight: 0,
      ccWeight: 0,
      volWeight: 21560,
      ltlWeight: 87958,
      apptWeight: 23254,
      palletsCount: 189,
      piecesCount: 1693,
      revenue: 65355.53,
      gateCutDay: null,
      gateCutTime: null,
      schedules: [
        {
          scheduleId: 3020858,
          loadPairScheduleId: 3081144,
          bookingNumber: "3351906",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91700,
            name: "ITS LOGISTICS, LLC",
            code: "L330",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "MIS",
          tripNumber: 3100137,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T17:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020861,
          loadPairScheduleId: 3081147,
          bookingNumber: "3351907",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91700,
            name: "ITS LOGISTICS, LLC",
            code: "L330",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "IND-PITD",
          tripNumber: 3100138,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T18:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020865,
          loadPairScheduleId: 3081151,
          bookingNumber: "3351897",
          opsCodeOrder: 1,
          tripPriority: 2,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91700,
            name: "ITS LOGISTICS, LLC",
            code: "L330",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "IND-PITD",
          tripNumber: 3100139,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020905,
          loadPairScheduleId: 3081190,
          bookingNumber: "0122406",
          opsCodeOrder: 2,
          tripPriority: 1,
          isPublished: true,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 109462,
            name: "EXPEDITED TRANS",
            code: "L358",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "EWR",
          tripNumber: 3100140,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T02:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020914,
          loadPairScheduleId: 3081199,
          bookingNumber: "10146",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91385,
            name: "MUSA LOGISTICS INC",
            code: "L372",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "SFO",
          tripNumber: 3100141,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T16:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020917,
          loadPairScheduleId: 3081202,
          bookingNumber: "10148",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91385,
            name: "MUSA LOGISTICS INC",
            code: "L372",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "ONT",
          tripNumber: 3100142,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T16:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020919,
          loadPairScheduleId: 3081204,
          bookingNumber: "342246",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95094,
            name: "ITF LLC",
            code: "L376",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "PER-DHRN",
          tripNumber: 3100143,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T18:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020920,
          loadPairScheduleId: 3081205,
          bookingNumber: "342247",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95094,
            name: "ITF LLC",
            code: "L376",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "MDW-CXIT",
          tripNumber: 3100144,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T18:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020923,
          loadPairScheduleId: 3081208,
          bookingNumber: "342249",
          opsCodeOrder: 1,
          tripPriority: 2,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95094,
            name: "ITF LLC",
            code: "L376",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "ONT",
          tripNumber: 3100145,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T18:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020935,
          loadPairScheduleId: 3081220,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109214,
            name: "SPEEDY TRANSPORT GROUP INC",
            code: "L384",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "TOR-SZTG",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T14:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020941,
          loadPairScheduleId: 3081226,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109436,
            name: "BANCROFT & SONS TRANSPORTATION LLC",
            code: "L389",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T16:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020976,
          loadPairScheduleId: 3081247,
          bookingNumber: "RAIMUNDO/WILLIAM",
          opsCodeOrder: 2,
          tripPriority: 2,
          isPublished: true,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92277,
            name: "VBZ GROUP CORP",
            code: "M124",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "MIS",
          tripNumber: 3100146,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T23:30:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021025,
          loadPairScheduleId: 3081279,
          bookingNumber: "ISRAEL",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92263,
            name: "MURILLO LOGISTIC INC",
            code: "M183",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "LRD",
          tripNumber: 3100147,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021034,
          loadPairScheduleId: 3081288,
          bookingNumber: "BENJAMIN",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92263,
            name: "MURILLO LOGISTIC INC",
            code: "M183",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "HTX",
          tripNumber: 3100148,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:30:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021045,
          loadPairScheduleId: 3081295,
          bookingNumber: "RYAN/VICTOR",
          opsCodeOrder: 1,
          tripPriority: 1,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92278,
            name: "MARSA TRANSPORTATION INC",
            code: "M199",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "ATM",
          tripNumber: 3100149,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T07:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021108,
          loadPairScheduleId: 3081335,
          bookingNumber: "KAIYUAN/DONGXUN",
          opsCodeOrder: 2,
          tripPriority: 1,
          isPublished: true,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92228,
            name: "EXJ TRUCKING INC",
            code: "M219",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "LAX",
          tripNumber: 3100150,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T20:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021162,
          loadPairScheduleId: 3081367,
          bookingNumber: "ATHER/TAHIR",
          opsCodeOrder: 1,
          tripPriority: 2,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92251,
            name: "ORANGE TRANSPORTATION LLC",
            code: "M250",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "PER-DHRN",
          tripNumber: 3100151,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T05:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021182,
          loadPairScheduleId: 3081377,
          bookingNumber: "GIESEMANN",
          opsCodeOrder: 2,
          tripPriority: 3,
          isPublished: true,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "C R ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "ONT",
          tripNumber: 3100152,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T15:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021236,
          loadPairScheduleId: 3081411,
          bookingNumber: "TEAM I",
          opsCodeOrder: 2,
          tripPriority: 2,
          isPublished: true,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "EWR",
          tripNumber: 3100153,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T20:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021263,
          loadPairScheduleId: 3081434,
          bookingNumber: "5942336",
          opsCodeOrder: 1,
          tripPriority: 3,
          isPublished: true,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "PER-DHRN",
          tripNumber: 3100154,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T18:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021350,
          loadPairScheduleId: 3081508,
          bookingNumber: "DAIJIAN LEI",
          opsCodeOrder: 2,
          tripPriority: 2,
          isPublished: true,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92264,
            name: "DLM TRUCKING LLC",
            code: "M206",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "DAL",
          destinationSicCode: "HTX",
          tripNumber: 3100155,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T04:30:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: [],
      shipments: [],
      mergedLanes: [],
      mergedOrigin: null,
    },
    {
      originSic: {
        id: 111,
        code: "ONT",
        ownership: "C",
        lhRegionId: 6,
        timeZone: "America/Los_Angeles",
      },
      destinationSic: null,
      tripsCount: 0,
      totalPulls: 9,
      pendingSchedulesCount: 13,
      confirmedSchedulesCount: 13,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 53,
      totalBills: 0,
      totalWeight: 0,
      loadedBills: 145,
      loadedWeight: 105304,
      ucBillsCount: 1,
      ccBillsCount: 0,
      volBillsCount: 0,
      ltlBillsCount: 95,
      apptBillsCount: 49,
      ucWeight: 825,
      ccWeight: 0,
      volWeight: 0,
      ltlWeight: 71289,
      apptWeight: 33190,
      palletsCount: 205,
      piecesCount: 941,
      revenue: 71891.61,
      gateCutDay: null,
      gateCutTime: null,
      schedules: [
        {
          scheduleId: 3020792,
          loadPairScheduleId: 3081080,
          bookingNumber: "2049427542",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 100904,
            name: "SCHNEIDER NATIONAL CARRIERS INC.",
            code: "L021",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "SAT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T08:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020803,
          loadPairScheduleId: 3081091,
          bookingNumber: "56643591",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91404,
            name: "CRST VAN EXPEDITED INC",
            code: "L028",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "HTX",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T12:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020822,
          loadPairScheduleId: 3081110,
          bookingNumber: "9973976",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92262,
            name: "PAM TRANSPORT, INC",
            code: "L303",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "SYR-PYLE",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T23:59:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020827,
          loadPairScheduleId: 3081115,
          bookingNumber: "LUIS GALDAMEZ",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109396,
            name: "ENDEAVOR FREIGHTWAYS, INC",
            code: "L310",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "VIS",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T23:59:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020828,
          loadPairScheduleId: 3081116,
          bookingNumber: "HECTOR",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109396,
            name: "ENDEAVOR FREIGHTWAYS, INC",
            code: "L310",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T23:59:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020841,
          loadPairScheduleId: 3081127,
          bookingNumber: "1132146",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 91389,
            name: "TRANSCO LINES, INC",
            code: "L313",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "DAL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T06:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020870,
          loadPairScheduleId: 3081156,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "ORL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T09:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020873,
          loadPairScheduleId: 3081159,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "ORH-RXIC",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T23:59:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020874,
          loadPairScheduleId: 3081160,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "RIC-AVRT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T06:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020875,
          loadPairScheduleId: 3081161,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "BWI-PDXE",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T12:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020881,
          loadPairScheduleId: 3081167,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "LIT-DHRN",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T04:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020890,
          loadPairScheduleId: 3081176,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "ATM",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T01:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020891,
          loadPairScheduleId: 3081177,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "MDW-CXIT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T05:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020893,
          loadPairScheduleId: 3081179,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95123,
            name: "PATIALA EXPRESS INC DBA PEI OHIO INC",
            code: "L335",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "CVG-ABNE",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T23:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020915,
          loadPairScheduleId: 3081200,
          bookingNumber: "6543",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 91385,
            name: "MUSA LOGISTICS INC",
            code: "L372",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T12:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020933,
          loadPairScheduleId: 3081218,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109214,
            name: "SPEEDY TRANSPORT GROUP INC",
            code: "L384",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "TOR-SZTG",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020934,
          loadPairScheduleId: 3081219,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 109214,
            name: "SPEEDY TRANSPORT GROUP INC",
            code: "L384",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "TOR-SZTG",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020937,
          loadPairScheduleId: 3081222,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 107585,
            name: "HD SHIPPING SOLUTIONS",
            code: "L386",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "OSH",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T12:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020945,
          loadPairScheduleId: 3081230,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 98564,
            name: "LYNN TRUCKING INC",
            code: "L392",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "DTW-DAFG",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T08:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020946,
          loadPairScheduleId: 3081231,
          bookingNumber: "",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 98564,
            name: "LYNN TRUCKING INC",
            code: "L392",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "CHT-AVRT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T08:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "PendingConfirmation",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020949,
          loadPairScheduleId: 3081233,
          bookingNumber: "CARLOS/ABRIAM",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92221,
            name: "BRICENO'S FREIGHT TRANSPORT LLC",
            code: "M103",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "PDZ-DLDS",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020972,
          loadPairScheduleId: 3081245,
          bookingNumber: "ALEXIS P/ ALEXIS A ",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92277,
            name: "VBZ GROUP CORP",
            code: "M124",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "MIS",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3020985,
          loadPairScheduleId: 3081252,
          bookingNumber: "ALVARO/JUAN",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92207,
            name: "JAMA TRANSPORT, INC",
            code: "M160",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "HVN",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T03:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021008,
          loadPairScheduleId: 3081265,
          bookingNumber: "ARTURO/GUS ",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92227,
            name: "HMC TRUCKING INC",
            code: "M169",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "COL-USCO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T07:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021010,
          loadPairScheduleId: 3081266,
          bookingNumber: "GEORGE",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92260,
            name: "MAESTRO CONVOY TRANSPORT INC",
            code: "M178",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T08:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021013,
          loadPairScheduleId: 3081269,
          bookingNumber: "JOSE/JORGE",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92260,
            name: "MAESTRO CONVOY TRANSPORT INC",
            code: "M178",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "PDZ-DLDS",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T02:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021039,
          loadPairScheduleId: 3081292,
          bookingNumber: "LUIS/JAREMIAS",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92252,
            name: "COSMOS EXPRESS INC",
            code: "M184",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "ATL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021047,
          loadPairScheduleId: 3081296,
          bookingNumber: "GABRIEL/SEUNG",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92278,
            name: "MARSA TRANSPORTATION INC",
            code: "M199",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "YRK-PYLE",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021057,
          loadPairScheduleId: 3081301,
          bookingNumber: "DANIEL/ALEJANDRO",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92278,
            name: "MARSA TRANSPORTATION INC",
            code: "M199",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "OSH",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T15:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021061,
          loadPairScheduleId: 3081303,
          bookingNumber: "RICHARD/KIM",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92278,
            name: "MARSA TRANSPORTATION INC",
            code: "M199",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "WCH-PYLE",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021066,
          loadPairScheduleId: 3081306,
          bookingNumber: "BRUCE/HONGMEI",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92211,
            name: "WTL TRUCKING INC",
            code: "M202",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T11:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021068,
          loadPairScheduleId: 3081307,
          bookingNumber: "LIGONG/ZHOUGON",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92211,
            name: "WTL TRUCKING INC",
            code: "M202",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "MDW-CXIT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T05:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021093,
          loadPairScheduleId: 3081324,
          bookingNumber: "MICHAEL/JEREMY",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92257,
            name: "HERRICK TRUCKING LLC",
            code: "M215",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021097,
          loadPairScheduleId: 3081328,
          bookingNumber: "RICHARD",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92257,
            name: "HERRICK TRUCKING LLC",
            code: "M215",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T22:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021101,
          loadPairScheduleId: 3081332,
          bookingNumber: "WEIYUAN ZHANG/GIWEI WU",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92209,
            name: "WYZ TRANSPORTATION INC",
            code: "M217",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "IND-DAFG",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T16:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021107,
          loadPairScheduleId: 3081335,
          bookingNumber: "KAIYUAN/DONGXUN",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92228,
            name: "EXJ TRUCKING INC",
            code: "M219",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "DAL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T23:59:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021112,
          loadPairScheduleId: 3081337,
          bookingNumber: "LAKHVIR/KULVIR",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92243,
            name: "J2L EXPRESS LLC",
            code: "M223",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T13:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021130,
          loadPairScheduleId: 3081347,
          bookingNumber: "AMANUEL/TONY",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92276,
            name: "JJM TRUCK INC",
            code: "M234",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "HTX",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T19:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021134,
          loadPairScheduleId: 3081350,
          bookingNumber: "DEVINDER/KULJIT",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 91694,
            name: "MANU TANU TRUCKING",
            code: "M243",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "WSH-PITD",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T12:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021143,
          loadPairScheduleId: 3081355,
          bookingNumber: "HO LIM/ PINGAN",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 91857,
            name: "CAT AND BEE LLC",
            code: "M246",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "NAS-AVRT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T07:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021159,
          loadPairScheduleId: 3081365,
          bookingNumber: "NADEEM/ZIA",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92251,
            name: "ORANGE TRANSPORTATION LLC",
            code: "M250",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "DAL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T20:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021186,
          loadPairScheduleId: 3081380,
          bookingNumber: "OZZY",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "C R ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T08:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021195,
          loadPairScheduleId: 3081384,
          bookingNumber: "NALMAR",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "C R ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "CHT-AVRT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021198,
          loadPairScheduleId: 3081386,
          bookingNumber: "MATTEN",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "C R ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "ORL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021200,
          loadPairScheduleId: 3081388,
          bookingNumber: "GONZALES",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "C R ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "BOS",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T09:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021214,
          loadPairScheduleId: 3081396,
          bookingNumber: "MOISES",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "C R ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "CLE-USCO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T17:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021226,
          loadPairScheduleId: 3081405,
          bookingNumber: "5942323",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "EWR",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T09:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021238,
          loadPairScheduleId: 3081413,
          bookingNumber: "5942331",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "DAL",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T13:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021243,
          loadPairScheduleId: 3081417,
          bookingNumber: "5942333",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "AFW",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T13:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021246,
          loadPairScheduleId: 3081420,
          bookingNumber: "5942322",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "LIT-DHRN",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T07:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021282,
          loadPairScheduleId: 3081450,
          bookingNumber: "5942320",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "ATM",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T02:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021288,
          loadPairScheduleId: 3081454,
          bookingNumber: "5942334",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "TUL-AGCE",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T12:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021328,
          loadPairScheduleId: 3081494,
          bookingNumber: "FIDEL",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 92282,
            name: "AC & SONS TRANSPORT LLC",
            code: "M255",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ONT",
          destinationSicCode: "VIS",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-06T20:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Shuttle",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: [],
      shipments: [],
      mergedLanes: [],
      mergedOrigin: null,
    },
    {
      originSic: {
        id: 173,
        code: "WSH-PITD",
        ownership: "V",
        lhRegionId: 4,
        timeZone: "America/Chicago",
      },
      destinationSic: null,
      tripsCount: 0,
      totalPulls: 9,
      pendingSchedulesCount: 0,
      confirmedSchedulesCount: 3,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 3,
      totalBills: 0,
      totalWeight: 0,
      loadedBills: 10,
      loadedWeight: 14993,
      ucBillsCount: 0,
      ccBillsCount: 0,
      volBillsCount: 0,
      ltlBillsCount: 6,
      apptBillsCount: 4,
      ucWeight: 0,
      ccWeight: 0,
      volWeight: 0,
      ltlWeight: 10928,
      apptWeight: 4065,
      palletsCount: 16,
      piecesCount: 64,
      revenue: 7053.46,
      gateCutDay: null,
      gateCutTime: null,
      schedules: [
        {
          scheduleId: 3020988,
          loadPairScheduleId: 3081253,
          bookingNumber: "ANGEL/EMERSON",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92207,
            name: "JAMA TRANSPORT, INC",
            code: "M160",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "WSH-PITD",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T11:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021104,
          loadPairScheduleId: 3081333,
          bookingNumber: "LIU/XINUAN",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92228,
            name: "EXJ TRUCKING INC",
            code: "M219",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "WSH-PITD",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T12:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021122,
          loadPairScheduleId: 3081342,
          bookingNumber: "JOSE/BENNY",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92220,
            name: "MAJESLY EXPRESS TRANSPORT INC",
            code: "M227",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "WSH-PITD",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: [],
      shipments: [],
      mergedLanes: [],
      mergedOrigin: null,
    },
  ],
  short: [
    {
      originSic: {
        id: 103,
        code: "ATB-SEFL",
        ownership: "V",
        lhRegionId: null,
        timeZone: "America/New_York",
      },
      destinationSic: null,
      tripsCount: 0,
      totalPulls: 9,
      pendingSchedulesCount: 0,
      confirmedSchedulesCount: 3,
      tripsWithStopsCount: 0,
      cancelledSchedulesCount: 0,
      schedulesCount: 3,
      totalBills: 0,
      totalWeight: 0,
      loadedBills: 20,
      loadedWeight: 17294,
      ucBillsCount: 1,
      ccBillsCount: 0,
      volBillsCount: 0,
      ltlBillsCount: 15,
      apptBillsCount: 4,
      ucWeight: 690,
      ccWeight: 0,
      volWeight: 0,
      ltlWeight: 15338,
      apptWeight: 1266,
      palletsCount: 37,
      piecesCount: 21,
      revenue: 10656.51,
      gateCutDay: null,
      gateCutTime: null,
      schedules: [
        {
          scheduleId: 3020966,
          loadPairScheduleId: 3081257,
          bookingNumber: "JOSE/ARMANDO",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 92250,
            name: "AVILENO TRUCKING LLC",
            code: "M163",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T09:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Fleet",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3031191,
          loadPairScheduleId: 3081382,
          bookingNumber: "OLIVE",
          opsCodeOrder: 2,
          tripPriority: 0,
          isPublished: false,
          loadType: "Return",
          hasLoadPair: true,
          employeeDrivers: [],
          carrier: {
            id: 95115,
            name: "C R ENGLAND INC",
            code: "L027",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "ONT",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "Dedicated",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
        {
          scheduleId: 3021237,
          loadPairScheduleId: 3081412,
          bookingNumber: "5942325",
          opsCodeOrder: 1,
          tripPriority: 0,
          isPublished: false,
          loadType: "Start",
          hasLoadPair: false,
          employeeDrivers: [],
          carrier: {
            id: 95128,
            name: "COVENANT TRANSPORT INC",
            code: "L004",
            isActive: true,
          },

          gateCutDay: 1,
          gateCutTime: "23:00:00",
          originSicCode: "ATB-SEFL",
          destinationSicCode: "SFO",
          tripNumber: null,
          isCancelled: false,
          hasStops: false,
          loadingDate: dayjs("2024-09-06T00:00:00"),
          pickupDate: dayjs("2024-09-07T10:00:00"),
          totalBills: 0,
          totalWeight: 0,
          loadedBills: 0,
          loadedWeight: 0,
          pallets: 0,
          pieces: 0,
          serviceType: "1-way",
          bookingStatus: "Confirmed",
          ccBills: 1,
          ucBills: 2,
          volBills: 3,
          apptBills: 0,
        },
      ],
      loadboardSchedules: [],
      shipments: [],
      mergedLanes: [],
      mergedOrigin: null,
    },
  ],
} as const satisfies Record<string, DaySummaryV2[]>;

export const mockTripDetails = {
  default: {
    sealNumber: "",
    cubageEstimate: null,
    scacCode: "CVEN",
    closeDate: dayjs(),
    departureTime: dayjs(),
    startLoadEta: dayjs(),
    scheduleId: 3022065,
    loadPairScheduleId: 9282663,
    bookingNumber: "TEAM H",
    bookingStatus: "Confirmed",
    tripStatus: "PLAN",
    originSicCode: "DAL",
    destinationSicCode: "EWR",
    loadingDate: dayjs("2024-09-26T00:00:00"),
    pickupDate: dayjs("2024-09-27T03:00:00"),
    gateCutDate: dayjs("2024-09-22T00:00:00"),
    serviceType: "Dedicated",
    carrierName: "COVENANT TRANSPORT INC",
    carrierCode: "L004",
    tripNumber: 3098840,
    trailerNumber: "123564987",
    deadheads: [],
    stops: [],
    tripPriority: 0,
    totalRevenue: 0,
    totalCost: 0,
    netLinehaul: 0,
    isPublished: true,
    isCancelled: false,
    loadType: "Start",
    hasLoadPair: true,
    shipments: [],
    tonu: 0,
    employeeDrivers: [],
    carrierRatePerMile: 0,
    totalBills: 30,
    totalWeight: 30000,
    ucBillsCount: 0,
    ccBillsCount: 0,
    apptBillsCount: 0,
    volBillsCount: 0,
    ltlBillsCount: 0,
    loadedBills: 0,
    loadedWeight: 0,
    piecesCount: 0,
    palletsCount: 0,
    ucBillsTotalWeight: 0,
    ccBillsTotalWeight: 0,
    volBillsTotalWeight: 0,
    apptBillsTotalWeight: 0,
    ltlBillsTotalWeight: 0,
    driverCheckInDetails: [],
  },
  enroute: {
    sealNumber: "",
    cubageEstimate: null,
    scacCode: "CVEN",
    closeDate: dayjs(),
    departureTime: dayjs(),
    startLoadEta: dayjs(),
    scheduleId: 3022865,
    loadPairScheduleId: 3082669,
    bookingNumber: "TEAM H",
    bookingStatus: "Confirmed",
    tripStatus: "ENRT",
    originSicCode: "DAL",
    destinationSicCode: "EWR",
    loadingDate: dayjs("2024-09-26T00:00:00"),
    pickupDate: dayjs("2024-09-27T03:00:00"),
    gateCutDate: dayjs("2024-09-22T00:00:00"),
    serviceType: "Dedicated",
    carrierName: "COVENANT TRANSPORT INC",
    carrierCode: "L004",
    tripNumber: 3098841,
    trailerNumber: "123564987",
    deadheads: [],
    stops: [],
    tripPriority: 0,
    totalRevenue: 0,
    totalCost: 0,
    netLinehaul: 0,
    isPublished: true,
    isCancelled: false,
    loadType: "Start",
    hasLoadPair: true,
    shipments: [],
    tonu: 0,
    employeeDrivers: [],
    carrierRatePerMile: 0,
    totalBills: 30,
    totalWeight: 30000,
    ucBillsCount: 0,
    ccBillsCount: 0,
    apptBillsCount: 0,
    volBillsCount: 0,
    ltlBillsCount: 0,
    loadedBills: 0,
    loadedWeight: 0,
    piecesCount: 0,
    palletsCount: 0,
    ucBillsTotalWeight: 0,
    ccBillsTotalWeight: 0,
    volBillsTotalWeight: 0,
    apptBillsTotalWeight: 0,
    ltlBillsTotalWeight: 0,
    driverCheckInDetails: [],
  },
} as const satisfies Record<string, TripDetail>;
