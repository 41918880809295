import { useTheme } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useSearchParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Filter } from "../../../../types/filter";
import { DaylightDateFormat } from "../../../../constants/DaylightDateFormat";
import { useSelectedServiceCenter } from "../../../../hooks/useSelectedServiceCenter";

type ETAFilterProps = { filter: Filter };

export const ETAFilter = ({ filter }: ETAFilterProps) => {
  const [serviceCenter] = useSelectedServiceCenter();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        sx={{ backgroundColor: theme.palette.background.paper }}
        label="ETA"
        format={DaylightDateFormat.DATE_WITH_SHORT_DAY_OF_WEEK_AND_TIME}
        defaultValue={
          searchParams.get(filter.queryParam)
            ? searchParams.get(filter.queryParam) !== "All"
              ? dayjs(searchParams.get(filter.queryParam))
              : null
            : null
        }
        onChange={(eta) => {
          setSearchParams((prev) => {
            const queryParam = filter.queryParam;
            const newParams = new URLSearchParams(prev);
            newParams.set(
              queryParam,
              eta
                ? eta.format(DaylightDateFormat.ISO_DATE_TIME_WITHOUT_TIMEZONE)
                : "All"
            );
            return newParams;
          });
        }}
        slotProps={{
          actionBar: {
            actions: ["clear", "accept"],
          },
          field: {
            readOnly: true,
          },
        }}
        timezone={serviceCenter.timeZone}
      />
    </LocalizationProvider>
  );
};
