import { LinehaulTrips } from "../../hooks/react-query/outbound/freightDetails/useGetLinehaulTrips";

export const linehaulTripsResponse = {
  empty: [],
  default: [
    {
      tripNumber: 3086375,
      gateDate: new Date(),
      gateTime: "10:30 AM",
      destinationSicId: 111,
      destinationSic: "ONT",
      stopSic: "ONT",
      stopSicId: 111,
      carrier: "C R ENGLAND INC",
    },
    {
      tripNumber: 3086387,
      gateDate: new Date(),
      gateTime: "10:30 AM",
      destinationSicId: 111,
      destinationSic: "ONT",
      stopSic: "",
      stopSicId: null,
      carrier: "ORANGE TRANSPORTATION LLC",
    },
  ],
} as const satisfies Record<string, LinehaulTrips>;
