import { Box, Chip, styled, Typography } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import { formatNumber } from "../../constants/utils";
import { useIsFeatureFlagEnabled } from "../../featureFlags/useIsFeatureFlagEnabled";
import { iconFillColors } from "./icons/Icon.types";
import PiecesIcon from "./icons/PiecesIcon";
import PalletsIcon from "./icons/PalletsIcon";

type ShipmentDetails = {
  bills: number;
  pallets: number;
  pieces: number;
  weight: number;
  unit?: string;
};

export interface DropdownStatusItemProps {
  label: string;
  status: string;
  shipmentDetails?: ShipmentDetails;
  bgColor?: string;
  color?: string;
}

const SectionBadgeRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
});

const ShipmentDetailWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const DropdownStatusItem = ({
  label,
  shipmentDetails,
  bgColor: backgroundColor,
  color,
  status,
}: DropdownStatusItemProps) => {
  const isShipmentDetailEnable = useIsFeatureFlagEnabled(
    "inbound-planning-assign-dialog-show-shipment-detail"
  );

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Box sx={{ marginBottom: "4px" }}>{label}</Box>
        {isShipmentDetailEnable && shipmentDetails && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="15"
          >
            <SectionBadgeRow>
              <ShipmentDetailWrapper>
                <ReceiptIcon
                  sx={{
                    color: iconFillColors.gray,
                    fontSize: 16,
                    marginRight: "4px",
                  }}
                />
                <Typography sx={{ fontSize: 12 }}>
                  {formatNumber(shipmentDetails.bills)}
                </Typography>
              </ShipmentDetailWrapper>
              <ShipmentDetailWrapper>
                <PalletsIcon
                  fill={iconFillColors.gray}
                  size={16}
                  style={{ marginRight: "4px" }}
                />
                <Typography sx={{ fontSize: 12 }}>
                  {formatNumber(shipmentDetails.pallets)}
                </Typography>
              </ShipmentDetailWrapper>
              <ShipmentDetailWrapper>
                <PiecesIcon
                  fill={iconFillColors.gray}
                  size={16}
                  style={{ marginRight: "4px" }}
                />
                <Typography sx={{ fontSize: 12 }}>
                  {formatNumber(shipmentDetails.pieces)}
                </Typography>
              </ShipmentDetailWrapper>
              <ShipmentDetailWrapper>
                <MonitorWeightIcon
                  sx={{
                    color: iconFillColors.gray,
                    fontSize: 16,
                    marginRight: "4px",
                  }}
                />
                <Typography sx={{ fontSize: 12 }}>
                  {formatNumber(shipmentDetails.weight)}{" "}
                  {shipmentDetails.unit ? shipmentDetails.unit : "lbs"}
                </Typography>
              </ShipmentDetailWrapper>
            </SectionBadgeRow>
          </Box>
        )}
      </Box>

      <Chip
        sx={{
          width: "min-content",
          height: "24px",
          backgroundColor,
          color,
        }}
        label={status}
      />
    </Box>
  );
};
export default DropdownStatusItem;
